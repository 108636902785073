const btcDummyData2 = [
	{
		"unix": 1646106180,
		"date": "2022-03-01 03:43:00",
		"symbol": "BTC/USD",
		"open": 43046.58,
		"high": 43046.58,
		"low": 43046.58,
		"close": 43046.58,
		"Volume BTC": 0,
		"Volume USD": 0
	},
	{
		"unix": 1646106060,
		"date": "2022-03-01 03:41:00",
		"symbol": "BTC/USD",
		"open": 43018.23,
		"high": 43046.59,
		"low": 43018.23,
		"close": 43046.58,
		"Volume BTC": 0.14297705,
		"Volume USD": 6154.673020989
	},
	{
		"unix": 1646106000,
		"date": "2022-03-01 03:40:00",
		"symbol": "BTC/USD",
		"open": 43022.24,
		"high": 43022.24,
		"low": 43016.03,
		"close": 43016.03,
		"Volume BTC": 0.00923,
		"Volume USD": 397.0379569
	},
	{
		"unix": 1646105940,
		"date": "2022-03-01 03:39:00",
		"symbol": "BTC/USD",
		"open": 43035.16,
		"high": 43035.16,
		"low": 42999.44,
		"close": 42999.44,
		"Volume BTC": 0.82095,
		"Volume USD": 35300.390268
	},
	{
		"unix": 1646105880,
		"date": "2022-03-01 03:38:00",
		"symbol": "BTC/USD",
		"open": 43077.82,
		"high": 43077.82,
		"low": 43049.46,
		"close": 43049.46,
		"Volume BTC": 0.02221034,
		"Volume USD": 956.1431434163999
	},
	{
		"unix": 1646105820,
		"date": "2022-03-01 03:37:00",
		"symbol": "BTC/USD",
		"open": 43078.73,
		"high": 43092.09,
		"low": 43078.73,
		"close": 43088.92,
		"Volume BTC": 1.59309482,
		"Volume USD": 68644.73525139439
	},
	{
		"unix": 1646105760,
		"date": "2022-03-01 03:36:00",
		"symbol": "BTC/USD",
		"open": 43095.57,
		"high": 43095.57,
		"low": 43068.85,
		"close": 43090.55,
		"Volume BTC": 0.06888172,
		"Volume USD": 2968.1511997459997
	},
	{
		"unix": 1646105700,
		"date": "2022-03-01 03:35:00",
		"symbol": "BTC/USD",
		"open": 43098.34,
		"high": 43098.42,
		"low": 43083.17,
		"close": 43083.17,
		"Volume BTC": 0.60903,
		"Volume USD": 26238.943025099998
	},
	{
		"unix": 1646105640,
		"date": "2022-03-01 03:34:00",
		"symbol": "BTC/USD",
		"open": 43091.15,
		"high": 43098.42,
		"low": 43053.76,
		"close": 43098.42,
		"Volume BTC": 0.09774787,
		"Volume USD": 4212.7787553654
	},
	{
		"unix": 1646105580,
		"date": "2022-03-01 03:33:00",
		"symbol": "BTC/USD",
		"open": 43073.99,
		"high": 43098.42,
		"low": 43073.99,
		"close": 43098.42,
		"Volume BTC": 0.0287456,
		"Volume USD": 1238.8899419519998
	},
	{
		"unix": 1646105520,
		"date": "2022-03-01 03:32:00",
		"symbol": "BTC/USD",
		"open": 43107.67,
		"high": 43107.67,
		"low": 43107.67,
		"close": 43107.67,
		"Volume BTC": 0,
		"Volume USD": 0
	},
	{
		"unix": 1646105460,
		"date": "2022-03-01 03:31:00",
		"symbol": "BTC/USD",
		"open": 43089.57,
		"high": 43107.67,
		"low": 43089.57,
		"close": 43107.67,
		"Volume BTC": 0.01657,
		"Volume USD": 714.2940919
	},
	{
		"unix": 1646105400,
		"date": "2022-03-01 03:30:00",
		"symbol": "BTC/USD",
		"open": 43065.82,
		"high": 43106.72,
		"low": 43065.82,
		"close": 43093.52,
		"Volume BTC": 0.31304999,
		"Volume USD": 13490.426005064799
	},
	{
		"unix": 1646105340,
		"date": "2022-03-01 03:29:00",
		"symbol": "BTC/USD",
		"open": 43085.12,
		"high": 43085.12,
		"low": 43042.51,
		"close": 43065.82,
		"Volume BTC": 0.01437843,
		"Volume USD": 619.2188782626
	},
	{
		"unix": 1646105280,
		"date": "2022-03-01 03:28:00",
		"symbol": "BTC/USD",
		"open": 43002,
		"high": 43047.74,
		"low": 42999.7,
		"close": 43047.74,
		"Volume BTC": 0.13463,
		"Volume USD": 5795.517236199999
	},
	{
		"unix": 1646105220,
		"date": "2022-03-01 03:27:00",
		"symbol": "BTC/USD",
		"open": 42953.79,
		"high": 42976.12,
		"low": 42937.05,
		"close": 42976.12,
		"Volume BTC": 1.4995318,
		"Volume USD": 64444.058580616
	},
	{
		"unix": 1646105160,
		"date": "2022-03-01 03:26:00",
		"symbol": "BTC/USD",
		"open": 43002.2,
		"high": 43002.2,
		"low": 42960.8,
		"close": 42960.8,
		"Volume BTC": 0.10754105,
		"Volume USD": 4620.04954084
	},
	{
		"unix": 1646105100,
		"date": "2022-03-01 03:25:00",
		"symbol": "BTC/USD",
		"open": 43018.57,
		"high": 43018.57,
		"low": 43002.3,
		"close": 43002.3,
		"Volume BTC": 0.00127046,
		"Volume USD": 54.632702058
	},
	{
		"unix": 1646105040,
		"date": "2022-03-01 03:24:00",
		"symbol": "BTC/USD",
		"open": 43011.93,
		"high": 43032.45,
		"low": 43004.32,
		"close": 43030.53,
		"Volume BTC": 0.15902,
		"Volume USD": 6842.7148806
	},
	{
		"unix": 1646104980,
		"date": "2022-03-01 03:23:00",
		"symbol": "BTC/USD",
		"open": 43009.83,
		"high": 43011.93,
		"low": 43009.83,
		"close": 43011.93,
		"Volume BTC": 0.01899,
		"Volume USD": 816.7965507
	},
	{
		"unix": 1646104920,
		"date": "2022-03-01 03:22:00",
		"symbol": "BTC/USD",
		"open": 42993.4,
		"high": 43009.83,
		"low": 42993.4,
		"close": 43009.83,
		"Volume BTC": 0.07475849,
		"Volume USD": 3215.3499459567
	},
	{
		"unix": 1646104860,
		"date": "2022-03-01 03:21:00",
		"symbol": "BTC/USD",
		"open": 42984.44,
		"high": 42993.55,
		"low": 42983.81,
		"close": 42983.81,
		"Volume BTC": 0.32832,
		"Volume USD": 14112.444499199999
	},
	{
		"unix": 1646104800,
		"date": "2022-03-01 03:20:00",
		"symbol": "BTC/USD",
		"open": 42982.96,
		"high": 42984.45,
		"low": 42982.96,
		"close": 42984.45,
		"Volume BTC": 0.26261,
		"Volume USD": 11288.146414499999
	},
	{
		"unix": 1646104740,
		"date": "2022-03-01 03:19:00",
		"symbol": "BTC/USD",
		"open": 42950.67,
		"high": 42982.96,
		"low": 42950.67,
		"close": 42982.96,
		"Volume BTC": 1.32063825,
		"Volume USD": 56764.94107422
	},
	{
		"unix": 1646104680,
		"date": "2022-03-01 03:18:00",
		"symbol": "BTC/USD",
		"open": 42918.62,
		"high": 42939.95,
		"low": 42878.15,
		"close": 42939.95,
		"Volume BTC": 1.48197563,
		"Volume USD": 63635.959453418494
	},
	{
		"unix": 1646104620,
		"date": "2022-03-01 03:17:00",
		"symbol": "BTC/USD",
		"open": 42971.37,
		"high": 42971.37,
		"low": 42870.04,
		"close": 42920.46,
		"Volume BTC": 8.82324927,
		"Volume USD": 378697.9173630642
	},
	{
		"unix": 1646104560,
		"date": "2022-03-01 03:16:00",
		"symbol": "BTC/USD",
		"open": 42982.97,
		"high": 42982.97,
		"low": 42981.47,
		"close": 42981.47,
		"Volume BTC": 0.00114,
		"Volume USD": 48.9988758
	},
	{
		"unix": 1646104500,
		"date": "2022-03-01 03:15:00",
		"symbol": "BTC/USD",
		"open": 42986.75,
		"high": 42986.98,
		"low": 42986.75,
		"close": 42986.98,
		"Volume BTC": 0.00771,
		"Volume USD": 331.4296158
	},
	{
		"unix": 1646104440,
		"date": "2022-03-01 03:14:00",
		"symbol": "BTC/USD",
		"open": 43002.29,
		"high": 43018.35,
		"low": 42970.72,
		"close": 42973.47,
		"Volume BTC": 0.94191319,
		"Volume USD": 40477.2782130693
	},
	{
		"unix": 1646104380,
		"date": "2022-03-01 03:13:00",
		"symbol": "BTC/USD",
		"open": 43073.78,
		"high": 43073.78,
		"low": 43031.39,
		"close": 43031.39,
		"Volume BTC": 0.00323,
		"Volume USD": 138.99138969999998
	},
	{
		"unix": 1646104320,
		"date": "2022-03-01 03:12:00",
		"symbol": "BTC/USD",
		"open": 43093.01,
		"high": 43093.01,
		"low": 43049.5,
		"close": 43049.5,
		"Volume BTC": 0.69997268,
		"Volume USD": 30133.473887660002
	},
	{
		"unix": 1646104260,
		"date": "2022-03-01 03:11:00",
		"symbol": "BTC/USD",
		"open": 43101.18,
		"high": 43101.18,
		"low": 43064.8,
		"close": 43093.02,
		"Volume BTC": 0.01719952,
		"Volume USD": 741.1792593503999
	},
	{
		"unix": 1646104200,
		"date": "2022-03-01 03:10:00",
		"symbol": "BTC/USD",
		"open": 43066.59,
		"high": 43088.9,
		"low": 43066.59,
		"close": 43088.9,
		"Volume BTC": 0.02650316,
		"Volume USD": 1141.9920109240002
	},
	{
		"unix": 1646104140,
		"date": "2022-03-01 03:09:00",
		"symbol": "BTC/USD",
		"open": 43097.75,
		"high": 43097.75,
		"low": 43044.8,
		"close": 43044.8,
		"Volume BTC": 0.01161777,
		"Volume USD": 500.084586096
	},
	{
		"unix": 1646104080,
		"date": "2022-03-01 03:08:00",
		"symbol": "BTC/USD",
		"open": 43109.3,
		"high": 43109.3,
		"low": 43109.3,
		"close": 43109.3,
		"Volume BTC": 0.0014,
		"Volume USD": 60.35302
	},
	{
		"unix": 1646104020,
		"date": "2022-03-01 03:07:00",
		"symbol": "BTC/USD",
		"open": 43088.98,
		"high": 43104.52,
		"low": 43076.08,
		"close": 43098.99,
		"Volume BTC": 0.37754339,
		"Volume USD": 16271.738790176098
	},
	{
		"unix": 1646103960,
		"date": "2022-03-01 03:06:00",
		"symbol": "BTC/USD",
		"open": 43088.67,
		"high": 43089.68,
		"low": 43067.62,
		"close": 43088.98,
		"Volume BTC": 0.54736878,
		"Volume USD": 23585.5624140444
	},
	{
		"unix": 1646103900,
		"date": "2022-03-01 03:05:00",
		"symbol": "BTC/USD",
		"open": 43105.61,
		"high": 43108.71,
		"low": 43059.49,
		"close": 43088.7,
		"Volume BTC": 0.07999394,
		"Volume USD": 3446.8348824779996
	},
	{
		"unix": 1646103840,
		"date": "2022-03-01 03:04:00",
		"symbol": "BTC/USD",
		"open": 43115.33,
		"high": 43115.33,
		"low": 43103.01,
		"close": 43103.01,
		"Volume BTC": 0.3636639,
		"Volume USD": 15675.008718339
	},
	{
		"unix": 1646103780,
		"date": "2022-03-01 03:03:00",
		"symbol": "BTC/USD",
		"open": 43110.91,
		"high": 43124.31,
		"low": 43102.53,
		"close": 43104.5,
		"Volume BTC": 0.42874872,
		"Volume USD": 18480.99920124
	},
	{
		"unix": 1646103720,
		"date": "2022-03-01 03:02:00",
		"symbol": "BTC/USD",
		"open": 43154.18,
		"high": 43154.18,
		"low": 43130.15,
		"close": 43130.33,
		"Volume BTC": 0.00447,
		"Volume USD": 192.79257510000002
	},
	{
		"unix": 1646103660,
		"date": "2022-03-01 03:01:00",
		"symbol": "BTC/USD",
		"open": 43146.12,
		"high": 43146.12,
		"low": 43127.9,
		"close": 43142,
		"Volume BTC": 0.12106,
		"Volume USD": 5222.77052
	},
	{
		"unix": 1646103600,
		"date": "2022-03-01 03:00:00",
		"symbol": "BTC/USD",
		"open": 43160.97,
		"high": 43161.72,
		"low": 43160.97,
		"close": 43161.21,
		"Volume BTC": 0.0061744,
		"Volume USD": 266.49457502399997
	},
	{
		"unix": 1646103540,
		"date": "2022-03-01 02:59:00",
		"symbol": "BTC/USD",
		"open": 43161.09,
		"high": 43161.09,
		"low": 43161.09,
		"close": 43161.09,
		"Volume BTC": 0.0050959,
		"Volume USD": 219.944598531
	},
	{
		"unix": 1646103480,
		"date": "2022-03-01 02:58:00",
		"symbol": "BTC/USD",
		"open": 43175.73,
		"high": 43175.73,
		"low": 43175.73,
		"close": 43175.73,
		"Volume BTC": 0.0439627,
		"Volume USD": 1898.1216652710002
	},
	{
		"unix": 1646103420,
		"date": "2022-03-01 02:57:00",
		"symbol": "BTC/USD",
		"open": 43148.58,
		"high": 43174.53,
		"low": 43134.39,
		"close": 43154.82,
		"Volume BTC": 4.23862594,
		"Volume USD": 182917.1394880308
	},
	{
		"unix": 1646103360,
		"date": "2022-03-01 02:56:00",
		"symbol": "BTC/USD",
		"open": 43145.32,
		"high": 43187.13,
		"low": 43145.32,
		"close": 43169.63,
		"Volume BTC": 0.72381991,
		"Volume USD": 31247.037701333298
	},
	{
		"unix": 1646103300,
		"date": "2022-03-01 02:55:00",
		"symbol": "BTC/USD",
		"open": 43117.21,
		"high": 43137.22,
		"low": 43117.21,
		"close": 43137.22,
		"Volume BTC": 0.04406678,
		"Volume USD": 1900.9183835516
	},
	{
		"unix": 1646103240,
		"date": "2022-03-01 02:54:00",
		"symbol": "BTC/USD",
		"open": 43098.94,
		"high": 43122.38,
		"low": 43098.94,
		"close": 43113.31,
		"Volume BTC": 0.09489748,
		"Volume USD": 4091.3444734588
	},
	{
		"unix": 1646103180,
		"date": "2022-03-01 02:53:00",
		"symbol": "BTC/USD",
		"open": 43145.62,
		"high": 43145.62,
		"low": 43120.8,
		"close": 43120.8,
		"Volume BTC": 0.35892415,
		"Volume USD": 15477.096487320001
	},
	{
		"unix": 1646103120,
		"date": "2022-03-01 02:52:00",
		"symbol": "BTC/USD",
		"open": 43092.36,
		"high": 43143.52,
		"low": 43092.36,
		"close": 43143.52,
		"Volume BTC": 2.56902753,
		"Volume USD": 110836.89062110559
	},
	{
		"unix": 1646103060,
		"date": "2022-03-01 02:51:00",
		"symbol": "BTC/USD",
		"open": 43122.71,
		"high": 43125.71,
		"low": 43091.2,
		"close": 43091.2,
		"Volume BTC": 0.64334,
		"Volume USD": 27722.292608
	},
	{
		"unix": 1646103000,
		"date": "2022-03-01 02:50:00",
		"symbol": "BTC/USD",
		"open": 43129.62,
		"high": 43137.71,
		"low": 43118.71,
		"close": 43122.71,
		"Volume BTC": 0.61865018,
		"Volume USD": 26677.872303587803
	},
	{
		"unix": 1646102940,
		"date": "2022-03-01 02:49:00",
		"symbol": "BTC/USD",
		"open": 43122.11,
		"high": 43122.11,
		"low": 43107.8,
		"close": 43107.8,
		"Volume BTC": 0.15071,
		"Volume USD": 6496.776538000001
	},
	{
		"unix": 1646102880,
		"date": "2022-03-01 02:48:00",
		"symbol": "BTC/USD",
		"open": 43121.22,
		"high": 43137.22,
		"low": 43121.22,
		"close": 43137.22,
		"Volume BTC": 0.00513,
		"Volume USD": 221.29393860000002
	},
	{
		"unix": 1646102820,
		"date": "2022-03-01 02:47:00",
		"symbol": "BTC/USD",
		"open": 43147.48,
		"high": 43151.22,
		"low": 43147.48,
		"close": 43151.22,
		"Volume BTC": 0.05723392,
		"Volume USD": 2469.7134733824
	},
	{
		"unix": 1646102760,
		"date": "2022-03-01 02:46:00",
		"symbol": "BTC/USD",
		"open": 43126.2,
		"high": 43158.12,
		"low": 43126.2,
		"close": 43147.7,
		"Volume BTC": 0.4625621,
		"Volume USD": 19958.490722169998
	},
	{
		"unix": 1646102700,
		"date": "2022-03-01 02:45:00",
		"symbol": "BTC/USD",
		"open": 43144.88,
		"high": 43144.88,
		"low": 43129.72,
		"close": 43137.61,
		"Volume BTC": 0.10327,
		"Volume USD": 4454.8209847
	},
	{
		"unix": 1646102640,
		"date": "2022-03-01 02:44:00",
		"symbol": "BTC/USD",
		"open": 43120.51,
		"high": 43133.69,
		"low": 43120.51,
		"close": 43133.69,
		"Volume BTC": 0.03317,
		"Volume USD": 1430.7444973
	},
	{
		"unix": 1646102580,
		"date": "2022-03-01 02:43:00",
		"symbol": "BTC/USD",
		"open": 43144.92,
		"high": 43146.95,
		"low": 43130.43,
		"close": 43130.43,
		"Volume BTC": 0.03442,
		"Volume USD": 1484.5494006
	},
	{
		"unix": 1646102520,
		"date": "2022-03-01 02:42:00",
		"symbol": "BTC/USD",
		"open": 43113.29,
		"high": 43125.61,
		"low": 43113.29,
		"close": 43125.61,
		"Volume BTC": 0.26562307,
		"Volume USD": 11455.1569238227
	},
	{
		"unix": 1646102460,
		"date": "2022-03-01 02:41:00",
		"symbol": "BTC/USD",
		"open": 43125.46,
		"high": 43125.61,
		"low": 43087.54,
		"close": 43125.32,
		"Volume BTC": 2.65684526,
		"Volume USD": 114577.3020279832
	},
	{
		"unix": 1646102400,
		"date": "2022-03-01 02:40:00",
		"symbol": "BTC/USD",
		"open": 43024.37,
		"high": 43118.91,
		"low": 43024.37,
		"close": 43118.91,
		"Volume BTC": 0.70905192,
		"Volume USD": 30573.5459238072
	},
	{
		"unix": 1646102340,
		"date": "2022-03-01 02:39:00",
		"symbol": "BTC/USD",
		"open": 43103.4,
		"high": 43103.4,
		"low": 43103.4,
		"close": 43103.4,
		"Volume BTC": 0,
		"Volume USD": 0
	},
	{
		"unix": 1646102280,
		"date": "2022-03-01 02:38:00",
		"symbol": "BTC/USD",
		"open": 43097.51,
		"high": 43104.81,
		"low": 43097.51,
		"close": 43103.4,
		"Volume BTC": 0.00459108,
		"Volume USD": 197.89115767200002
	},
	{
		"unix": 1646102220,
		"date": "2022-03-01 02:37:00",
		"symbol": "BTC/USD",
		"open": 43117.21,
		"high": 43125.69,
		"low": 43117.21,
		"close": 43125.69,
		"Volume BTC": 0.00711269,
		"Volume USD": 306.7396640061
	},
	{
		"unix": 1646102160,
		"date": "2022-03-01 02:36:00",
		"symbol": "BTC/USD",
		"open": 43100,
		"high": 43138.9,
		"low": 43100,
		"close": 43118.31,
		"Volume BTC": 1.17307,
		"Volume USD": 50580.7959117
	},
	{
		"unix": 1646102100,
		"date": "2022-03-01 02:35:00",
		"symbol": "BTC/USD",
		"open": 43064.6,
		"high": 43091.17,
		"low": 43054.9,
		"close": 43091.17,
		"Volume BTC": 0.10975369,
		"Volume USD": 4729.4149139173
	},
	{
		"unix": 1646102040,
		"date": "2022-03-01 02:34:00",
		"symbol": "BTC/USD",
		"open": 43026.54,
		"high": 43098.51,
		"low": 43026.54,
		"close": 43084.5,
		"Volume BTC": 0.49587754,
		"Volume USD": 21364.63587213
	},
	{
		"unix": 1646101980,
		"date": "2022-03-01 02:33:00",
		"symbol": "BTC/USD",
		"open": 43044.56,
		"high": 43075.4,
		"low": 43044.56,
		"close": 43071.75,
		"Volume BTC": 1.11705393,
		"Volume USD": 48113.4676094775
	},
	{
		"unix": 1646101920,
		"date": "2022-03-01 02:32:00",
		"symbol": "BTC/USD",
		"open": 43042.21,
		"high": 43048.89,
		"low": 43026.72,
		"close": 43026.72,
		"Volume BTC": 1.52530362,
		"Volume USD": 65628.8117727264
	},
	{
		"unix": 1646101860,
		"date": "2022-03-01 02:31:00",
		"symbol": "BTC/USD",
		"open": 43056.8,
		"high": 43060.3,
		"low": 43023.42,
		"close": 43024.5,
		"Volume BTC": 2.32963404,
		"Volume USD": 100231.33975398
	},
	{
		"unix": 1646101800,
		"date": "2022-03-01 02:30:00",
		"symbol": "BTC/USD",
		"open": 43080.42,
		"high": 43080.42,
		"low": 43036.61,
		"close": 43036.61,
		"Volume BTC": 0.65881583,
		"Volume USD": 28353.1999375363
	},
	{
		"unix": 1646101740,
		"date": "2022-03-01 02:29:00",
		"symbol": "BTC/USD",
		"open": 43051.6,
		"high": 43083.5,
		"low": 43051.6,
		"close": 43083.5,
		"Volume BTC": 0.14447,
		"Volume USD": 6224.273244999999
	},
	{
		"unix": 1646101680,
		"date": "2022-03-01 02:28:00",
		"symbol": "BTC/USD",
		"open": 43107.01,
		"high": 43107.01,
		"low": 43065.1,
		"close": 43065.39,
		"Volume BTC": 0.312454,
		"Volume USD": 13455.95336706
	},
	{
		"unix": 1646101620,
		"date": "2022-03-01 02:27:00",
		"symbol": "BTC/USD",
		"open": 43181.85,
		"high": 43181.85,
		"low": 43103.32,
		"close": 43103.32,
		"Volume BTC": 0.40782377,
		"Volume USD": 17578.5584619164
	},
	{
		"unix": 1646101560,
		"date": "2022-03-01 02:26:00",
		"symbol": "BTC/USD",
		"open": 43196.62,
		"high": 43196.62,
		"low": 43169.67,
		"close": 43194.13,
		"Volume BTC": 1.73609188,
		"Volume USD": 74988.9783566644
	},
	{
		"unix": 1646101500,
		"date": "2022-03-01 02:25:00",
		"symbol": "BTC/USD",
		"open": 43220.94,
		"high": 43223.14,
		"low": 43220.94,
		"close": 43223.14,
		"Volume BTC": 0.013,
		"Volume USD": 561.90082
	},
	{
		"unix": 1646101440,
		"date": "2022-03-01 02:24:00",
		"symbol": "BTC/USD",
		"open": 43234.03,
		"high": 43234.03,
		"low": 43234.03,
		"close": 43234.03,
		"Volume BTC": 0,
		"Volume USD": 0
	},
	{
		"unix": 1646101380,
		"date": "2022-03-01 02:23:00",
		"symbol": "BTC/USD",
		"open": 43233.7,
		"high": 43237.74,
		"low": 43218.24,
		"close": 43234.03,
		"Volume BTC": 1.38038698,
		"Volume USD": 59679.692104929396
	},
	{
		"unix": 1646101320,
		"date": "2022-03-01 02:22:00",
		"symbol": "BTC/USD",
		"open": 43201.93,
		"high": 43247.75,
		"low": 43201.93,
		"close": 43233.7,
		"Volume BTC": 0.1356176,
		"Volume USD": 5863.25063312
	},
	{
		"unix": 1646101260,
		"date": "2022-03-01 02:21:00",
		"symbol": "BTC/USD",
		"open": 43180.67,
		"high": 43206.92,
		"low": 43166.99,
		"close": 43174.67,
		"Volume BTC": 0.26201178,
		"Volume USD": 11312.272137612601
	},
	{
		"unix": 1646101200,
		"date": "2022-03-01 02:20:00",
		"symbol": "BTC/USD",
		"open": 43189.52,
		"high": 43199.03,
		"low": 43175.86,
		"close": 43178.1,
		"Volume BTC": 0.57126778,
		"Volume USD": 24666.257331618
	},
	{
		"unix": 1646101140,
		"date": "2022-03-01 02:19:00",
		"symbol": "BTC/USD",
		"open": 43220.93,
		"high": 43220.93,
		"low": 43155.28,
		"close": 43188.09,
		"Volume BTC": 1.15021,
		"Volume USD": 49675.37299889999
	},
	{
		"unix": 1646101080,
		"date": "2022-03-01 02:18:00",
		"symbol": "BTC/USD",
		"open": 43161.23,
		"high": 43208.03,
		"low": 43161.23,
		"close": 43197.83,
		"Volume BTC": 0.09406996,
		"Volume USD": 4063.6181401868
	},
	{
		"unix": 1646101020,
		"date": "2022-03-01 02:17:00",
		"symbol": "BTC/USD",
		"open": 43118.81,
		"high": 43178.33,
		"low": 43098.22,
		"close": 43138.52,
		"Volume BTC": 0.01664449,
		"Volume USD": 718.0186647548
	},
	{
		"unix": 1646100960,
		"date": "2022-03-01 02:16:00",
		"symbol": "BTC/USD",
		"open": 42998.08,
		"high": 43107.31,
		"low": 42998.08,
		"close": 43107.31,
		"Volume BTC": 0.72569975,
		"Volume USD": 31282.964090172496
	},
	{
		"unix": 1646100900,
		"date": "2022-03-01 02:15:00",
		"symbol": "BTC/USD",
		"open": 43116.71,
		"high": 43117.21,
		"low": 42940.42,
		"close": 43009.25,
		"Volume BTC": 19.31934339,
		"Volume USD": 830910.4696963575
	},
	{
		"unix": 1646100840,
		"date": "2022-03-01 02:14:00",
		"symbol": "BTC/USD",
		"open": 43150.62,
		"high": 43166.7,
		"low": 43115.82,
		"close": 43116.71,
		"Volume BTC": 1.67516222,
		"Volume USD": 72227.4836426962
	},
	{
		"unix": 1646100780,
		"date": "2022-03-01 02:13:00",
		"symbol": "BTC/USD",
		"open": 43116.38,
		"high": 43138.66,
		"low": 43116.38,
		"close": 43136.62,
		"Volume BTC": 0.08941862,
		"Volume USD": 3857.2170318644003
	},
	{
		"unix": 1646100720,
		"date": "2022-03-01 02:12:00",
		"symbol": "BTC/USD",
		"open": 43141.86,
		"high": 43141.86,
		"low": 43092.21,
		"close": 43115.19,
		"Volume BTC": 0.09546105,
		"Volume USD": 4115.8213083495
	},
	{
		"unix": 1646100660,
		"date": "2022-03-01 02:11:00",
		"symbol": "BTC/USD",
		"open": 43159.1,
		"high": 43174.93,
		"low": 43117.9,
		"close": 43148.29,
		"Volume BTC": 2.74513092,
		"Volume USD": 118447.7050241268
	},
	{
		"unix": 1646100600,
		"date": "2022-03-01 02:10:00",
		"symbol": "BTC/USD",
		"open": 43109.61,
		"high": 43147.1,
		"low": 43080,
		"close": 43142.97,
		"Volume BTC": 3.28287129,
		"Volume USD": 141632.8175783313
	},
	{
		"unix": 1646100540,
		"date": "2022-03-01 02:09:00",
		"symbol": "BTC/USD",
		"open": 43170.83,
		"high": 43172.23,
		"low": 43128.12,
		"close": 43139.57,
		"Volume BTC": 0.51041758,
		"Volume USD": 22019.194921640603
	},
	{
		"unix": 1646100480,
		"date": "2022-03-01 02:08:00",
		"symbol": "BTC/USD",
		"open": 43172.73,
		"high": 43184.63,
		"low": 43152.02,
		"close": 43182.73,
		"Volume BTC": 0.15964542,
		"Volume USD": 6893.925067596601
	},
	{
		"unix": 1646100420,
		"date": "2022-03-01 02:07:00",
		"symbol": "BTC/USD",
		"open": 43162.42,
		"high": 43200.23,
		"low": 43146.89,
		"close": 43192.08,
		"Volume BTC": 0.43405064,
		"Volume USD": 18747.5499669312
	},
	{
		"unix": 1646100360,
		"date": "2022-03-01 02:06:00",
		"symbol": "BTC/USD",
		"open": 43195.33,
		"high": 43195.33,
		"low": 43150,
		"close": 43162.42,
		"Volume BTC": 3.0660239,
		"Volume USD": 132337.01130183798
	},
	{
		"unix": 1646100300,
		"date": "2022-03-01 02:05:00",
		"symbol": "BTC/USD",
		"open": 43254.75,
		"high": 43254.75,
		"low": 43173.83,
		"close": 43190.03,
		"Volume BTC": 0.30121,
		"Volume USD": 13009.268936299999
	},
	{
		"unix": 1646100240,
		"date": "2022-03-01 02:04:00",
		"symbol": "BTC/USD",
		"open": 43248.35,
		"high": 43254.75,
		"low": 43200,
		"close": 43229.85,
		"Volume BTC": 3.67130068,
		"Volume USD": 158709.77770129798
	},
	{
		"unix": 1646100180,
		"date": "2022-03-01 02:03:00",
		"symbol": "BTC/USD",
		"open": 43276.65,
		"high": 43276.65,
		"low": 43234.81,
		"close": 43234.81,
		"Volume BTC": 1.86305761,
		"Volume USD": 80548.9417874041
	},
	{
		"unix": 1646100120,
		"date": "2022-03-01 02:02:00",
		"symbol": "BTC/USD",
		"open": 43274.92,
		"high": 43298.56,
		"low": 43260.25,
		"close": 43263.05,
		"Volume BTC": 0.56958787,
		"Volume USD": 24642.108499203503
	},
	{
		"unix": 1646100060,
		"date": "2022-03-01 02:01:00",
		"symbol": "BTC/USD",
		"open": 43301.26,
		"high": 43325.97,
		"low": 43287.82,
		"close": 43287.82,
		"Volume BTC": 0.71613007,
		"Volume USD": 30999.7095667474
	},
	{
		"unix": 1646100000,
		"date": "2022-03-01 02:00:00",
		"symbol": "BTC/USD",
		"open": 43290.16,
		"high": 43301.26,
		"low": 43277.55,
		"close": 43296.76,
		"Volume BTC": 0.24361641,
		"Volume USD": 10547.801235831601
	},
	{
		"unix": 1646099940,
		"date": "2022-03-01 01:59:00",
		"symbol": "BTC/USD",
		"open": 43283.97,
		"high": 43299.26,
		"low": 43275.55,
		"close": 43296.36,
		"Volume BTC": 1.49989975,
		"Volume USD": 64940.19953991
	},
	{
		"unix": 1646099880,
		"date": "2022-03-01 01:58:00",
		"symbol": "BTC/USD",
		"open": 43273.95,
		"high": 43300.88,
		"low": 43258.75,
		"close": 43258.75,
		"Volume BTC": 0.94264718,
		"Volume USD": 40777.738697825
	},
	{
		"unix": 1646099820,
		"date": "2022-03-01 01:57:00",
		"symbol": "BTC/USD",
		"open": 43313.76,
		"high": 43329.88,
		"low": 43271.46,
		"close": 43273.95,
		"Volume BTC": 5.09301502,
		"Volume USD": 220394.877324729
	},
	{
		"unix": 1646099760,
		"date": "2022-03-01 01:56:00",
		"symbol": "BTC/USD",
		"open": 43376.18,
		"high": 43376.18,
		"low": 43315.1,
		"close": 43317.96,
		"Volume BTC": 0.30822,
		"Volume USD": 13351.4616312
	},
	{
		"unix": 1646099700,
		"date": "2022-03-01 01:55:00",
		"symbol": "BTC/USD",
		"open": 43406.29,
		"high": 43406.29,
		"low": 43358.68,
		"close": 43358.68,
		"Volume BTC": 1.81046907,
		"Volume USD": 78499.5490560276
	},
	{
		"unix": 1646099640,
		"date": "2022-03-01 01:54:00",
		"symbol": "BTC/USD",
		"open": 43365.68,
		"high": 43416.09,
		"low": 43365.68,
		"close": 43404.59,
		"Volume BTC": 0.53269642,
		"Volume USD": 23121.469704567797
	},
	{
		"unix": 1646099580,
		"date": "2022-03-01 01:53:00",
		"symbol": "BTC/USD",
		"open": 43362.4,
		"high": 43400.11,
		"low": 43349.44,
		"close": 43360.73,
		"Volume BTC": 6.2641585,
		"Volume USD": 271618.48539570504
	},
	{
		"unix": 1646099520,
		"date": "2022-03-01 01:52:00",
		"symbol": "BTC/USD",
		"open": 43436.2,
		"high": 43436.2,
		"low": 43412.42,
		"close": 43412.42,
		"Volume BTC": 0.11203452,
		"Volume USD": 4863.6896367384
	},
	{
		"unix": 1646099460,
		"date": "2022-03-01 01:51:00",
		"symbol": "BTC/USD",
		"open": 43421.49,
		"high": 43437.5,
		"low": 43419.32,
		"close": 43431.39,
		"Volume BTC": 0.50500935,
		"Volume USD": 21933.258033496502
	},
	{
		"unix": 1646099400,
		"date": "2022-03-01 01:50:00",
		"symbol": "BTC/USD",
		"open": 43416.22,
		"high": 43478.94,
		"low": 43391.98,
		"close": 43419.49,
		"Volume BTC": 41.15933902,
		"Volume USD": 1787117.5089854996
	},
	{
		"unix": 1646099340,
		"date": "2022-03-01 01:49:00",
		"symbol": "BTC/USD",
		"open": 43388.38,
		"high": 43416.49,
		"low": 43386.82,
		"close": 43416.49,
		"Volume BTC": 35.27943521,
		"Volume USD": 1531709.2460006129
	},
	{
		"unix": 1646099280,
		"date": "2022-03-01 01:48:00",
		"symbol": "BTC/USD",
		"open": 43383.03,
		"high": 43404.09,
		"low": 43378.88,
		"close": 43388.38,
		"Volume BTC": 0.16315,
		"Volume USD": 7078.814196999999
	},
	{
		"unix": 1646099220,
		"date": "2022-03-01 01:47:00",
		"symbol": "BTC/USD",
		"open": 43381.38,
		"high": 43398.4,
		"low": 43379.8,
		"close": 43383.03,
		"Volume BTC": 0.1055,
		"Volume USD": 4576.909665
	},
	{
		"unix": 1646099160,
		"date": "2022-03-01 01:46:00",
		"symbol": "BTC/USD",
		"open": 43368.87,
		"high": 43409.69,
		"low": 43367.56,
		"close": 43367.56,
		"Volume BTC": 0.10535984,
		"Volume USD": 4569.199182790399
	},
	{
		"unix": 1646099100,
		"date": "2022-03-01 01:45:00",
		"symbol": "BTC/USD",
		"open": 43416.19,
		"high": 43416.19,
		"low": 43362.48,
		"close": 43362.48,
		"Volume BTC": 0.44246446,
		"Volume USD": 19186.356297460803
	},
	{
		"unix": 1646099040,
		"date": "2022-03-01 01:44:00",
		"symbol": "BTC/USD",
		"open": 43432.87,
		"high": 43451.98,
		"low": 43413.91,
		"close": 43413.91,
		"Volume BTC": 0.5333,
		"Volume USD": 23152.638203000002
	},
	{
		"unix": 1646098980,
		"date": "2022-03-01 01:43:00",
		"symbol": "BTC/USD",
		"open": 43419.49,
		"high": 43433.3,
		"low": 43397.48,
		"close": 43432.54,
		"Volume BTC": 0.5437228,
		"Volume USD": 23615.262259911997
	},
	{
		"unix": 1646098920,
		"date": "2022-03-01 01:42:00",
		"symbol": "BTC/USD",
		"open": 43434.35,
		"high": 43434.35,
		"low": 43400.84,
		"close": 43400.84,
		"Volume BTC": 0.05439,
		"Volume USD": 2360.5716875999997
	},
	{
		"unix": 1646098860,
		"date": "2022-03-01 01:41:00",
		"symbol": "BTC/USD",
		"open": 43441.49,
		"high": 43441.49,
		"low": 43428.34,
		"close": 43440.83,
		"Volume BTC": 0.455,
		"Volume USD": 19765.577650000003
	},
	{
		"unix": 1646098800,
		"date": "2022-03-01 01:40:00",
		"symbol": "BTC/USD",
		"open": 43348.12,
		"high": 43442.8,
		"low": 43280.28,
		"close": 43442.8,
		"Volume BTC": 2.92970491,
		"Volume USD": 127274.584464148
	},
	{
		"unix": 1646098740,
		"date": "2022-03-01 01:39:00",
		"symbol": "BTC/USD",
		"open": 43323.23,
		"high": 43355.96,
		"low": 43323.23,
		"close": 43353.36,
		"Volume BTC": 0.05180242,
		"Volume USD": 2245.8089631312
	},
	{
		"unix": 1646098680,
		"date": "2022-03-01 01:38:00",
		"symbol": "BTC/USD",
		"open": 43353.37,
		"high": 43353.37,
		"low": 43343.38,
		"close": 43350.24,
		"Volume BTC": 0.09689,
		"Volume USD": 4200.2047536
	},
	{
		"unix": 1646098620,
		"date": "2022-03-01 01:37:00",
		"symbol": "BTC/USD",
		"open": 43351.27,
		"high": 43365.08,
		"low": 43351.27,
		"close": 43353.46,
		"Volume BTC": 0.13902,
		"Volume USD": 6026.9980092000005
	},
	{
		"unix": 1646098560,
		"date": "2022-03-01 01:36:00",
		"symbol": "BTC/USD",
		"open": 43333.98,
		"high": 43351.77,
		"low": 43325.97,
		"close": 43350.77,
		"Volume BTC": 0.20232,
		"Volume USD": 8770.727786399999
	},
	{
		"unix": 1646098500,
		"date": "2022-03-01 01:35:00",
		"symbol": "BTC/USD",
		"open": 43314.26,
		"high": 43326.37,
		"low": 43314.26,
		"close": 43324.1,
		"Volume BTC": 0.07542,
		"Volume USD": 3267.5036219999997
	},
	{
		"unix": 1646098440,
		"date": "2022-03-01 01:34:00",
		"symbol": "BTC/USD",
		"open": 43335.14,
		"high": 43335.14,
		"low": 43290.24,
		"close": 43309.34,
		"Volume BTC": 0.51225677,
		"Volume USD": 22185.5026192318
	},
	{
		"unix": 1646098380,
		"date": "2022-03-01 01:33:00",
		"symbol": "BTC/USD",
		"open": 43330.57,
		"high": 43356.64,
		"low": 43324.7,
		"close": 43356.64,
		"Volume BTC": 0.54256462,
		"Volume USD": 23523.7789060768
	},
	{
		"unix": 1646098320,
		"date": "2022-03-01 01:32:00",
		"symbol": "BTC/USD",
		"open": 43310.14,
		"high": 43333.23,
		"low": 43310.14,
		"close": 43327.54,
		"Volume BTC": 0.15714,
		"Volume USD": 6808.4896356
	},
	{
		"unix": 1646098260,
		"date": "2022-03-01 01:31:00",
		"symbol": "BTC/USD",
		"open": 43330.44,
		"high": 43345.32,
		"low": 43318.8,
		"close": 43327.77,
		"Volume BTC": 0.74271,
		"Volume USD": 32179.968056699996
	},
	{
		"unix": 1646098200,
		"date": "2022-03-01 01:30:00",
		"symbol": "BTC/USD",
		"open": 43285.94,
		"high": 43333.36,
		"low": 43267.64,
		"close": 43333.36,
		"Volume BTC": 0.17583355,
		"Volume USD": 7619.458522228
	},
	{
		"unix": 1646098140,
		"date": "2022-03-01 01:29:00",
		"symbol": "BTC/USD",
		"open": 43298.94,
		"high": 43302.75,
		"low": 43275.95,
		"close": 43289.21,
		"Volume BTC": 0.22851464,
		"Volume USD": 9892.218239034399
	},
	{
		"unix": 1646098080,
		"date": "2022-03-01 01:28:00",
		"symbol": "BTC/USD",
		"open": 43288.53,
		"high": 43323.25,
		"low": 43288.53,
		"close": 43319.76,
		"Volume BTC": 0.19462354,
		"Volume USD": 8431.045043150401
	},
	{
		"unix": 1646098020,
		"date": "2022-03-01 01:27:00",
		"symbol": "BTC/USD",
		"open": 43307.25,
		"high": 43310.46,
		"low": 43288.84,
		"close": 43290.63,
		"Volume BTC": 0.14668177,
		"Volume USD": 6349.946232815099
	},
	{
		"unix": 1646097960,
		"date": "2022-03-01 01:26:00",
		"symbol": "BTC/USD",
		"open": 43327.46,
		"high": 43327.46,
		"low": 43289.94,
		"close": 43298.33,
		"Volume BTC": 0.19953241,
		"Volume USD": 8639.420133875301
	},
	{
		"unix": 1646097900,
		"date": "2022-03-01 01:25:00",
		"symbol": "BTC/USD",
		"open": 43354.45,
		"high": 43354.45,
		"low": 43280.3,
		"close": 43324.86,
		"Volume BTC": 7.50587521,
		"Volume USD": 325190.9926507206
	},
	{
		"unix": 1646097840,
		"date": "2022-03-01 01:24:00",
		"symbol": "BTC/USD",
		"open": 43328.95,
		"high": 43365.48,
		"low": 43328.95,
		"close": 43365.48,
		"Volume BTC": 0.08169728,
		"Volume USD": 3542.8417618944
	},
	{
		"unix": 1646097780,
		"date": "2022-03-01 01:23:00",
		"symbol": "BTC/USD",
		"open": 43388.63,
		"high": 43388.63,
		"low": 43337.32,
		"close": 43342.76,
		"Volume BTC": 1.76396442,
		"Volume USD": 76455.0865045992
	},
	{
		"unix": 1646097720,
		"date": "2022-03-01 01:22:00",
		"symbol": "BTC/USD",
		"open": 43338.11,
		"high": 43383.71,
		"low": 43338.11,
		"close": 43383.71,
		"Volume BTC": 1.19343048,
		"Volume USD": 51775.4418494808
	},
	{
		"unix": 1646097660,
		"date": "2022-03-01 01:21:00",
		"symbol": "BTC/USD",
		"open": 43341.64,
		"high": 43352.45,
		"low": 43331.98,
		"close": 43352.45,
		"Volume BTC": 0.23350395,
		"Volume USD": 10122.9683171775
	},
	{
		"unix": 1646097600,
		"date": "2022-03-01 01:20:00",
		"symbol": "BTC/USD",
		"open": 43296.65,
		"high": 43330.83,
		"low": 43296.65,
		"close": 43330.83,
		"Volume BTC": 1.34191962,
		"Volume USD": 58146.49092788461
	},
	{
		"unix": 1646097540,
		"date": "2022-03-01 01:19:00",
		"symbol": "BTC/USD",
		"open": 43253.42,
		"high": 43309.21,
		"low": 43253.42,
		"close": 43290.4,
		"Volume BTC": 7.03366156,
		"Volume USD": 304490.022397024
	},
	{
		"unix": 1646097480,
		"date": "2022-03-01 01:18:00",
		"symbol": "BTC/USD",
		"open": 43262.39,
		"high": 43265.97,
		"low": 43235.45,
		"close": 43265.97,
		"Volume BTC": 1.36527658,
		"Volume USD": 59070.0155519826
	},
	{
		"unix": 1646097420,
		"date": "2022-03-01 01:17:00",
		"symbol": "BTC/USD",
		"open": 43281.05,
		"high": 43296.26,
		"low": 43250,
		"close": 43281.66,
		"Volume BTC": 0.52724854,
		"Volume USD": 22820.192043776402
	},
	{
		"unix": 1646097360,
		"date": "2022-03-01 01:16:00",
		"symbol": "BTC/USD",
		"open": 43270.82,
		"high": 43296.65,
		"low": 43261.76,
		"close": 43296.65,
		"Volume BTC": 0.69853411,
		"Volume USD": 30244.1868737315
	},
	{
		"unix": 1646097300,
		"date": "2022-03-01 01:15:00",
		"symbol": "BTC/USD",
		"open": 43296.65,
		"high": 43308.36,
		"low": 43267.38,
		"close": 43270.8,
		"Volume BTC": 8.77421455,
		"Volume USD": 379667.28295014
	},
	{
		"unix": 1646097240,
		"date": "2022-03-01 01:14:00",
		"symbol": "BTC/USD",
		"open": 43335.36,
		"high": 43345.46,
		"low": 43263.59,
		"close": 43296.65,
		"Volume BTC": 11.95468064,
		"Volume USD": 517597.623531856
	},
	{
		"unix": 1646097180,
		"date": "2022-03-01 01:13:00",
		"symbol": "BTC/USD",
		"open": 43364.81,
		"high": 43372.27,
		"low": 43357.07,
		"close": 43360.76,
		"Volume BTC": 0.05987887,
		"Volume USD": 2596.3933111412002
	},
	{
		"unix": 1646097120,
		"date": "2022-03-01 01:12:00",
		"symbol": "BTC/USD",
		"open": 43422.99,
		"high": 43430.52,
		"low": 43399.59,
		"close": 43430.5,
		"Volume BTC": 0.77388,
		"Volume USD": 33609.99534
	},
	{
		"unix": 1646097060,
		"date": "2022-03-01 01:11:00",
		"symbol": "BTC/USD",
		"open": 43474.91,
		"high": 43474.91,
		"low": 43414.27,
		"close": 43414.27,
		"Volume BTC": 1.31878167,
		"Volume USD": 57253.943492430895
	},
	{
		"unix": 1646097000,
		"date": "2022-03-01 01:10:00",
		"symbol": "BTC/USD",
		"open": 43460.2,
		"high": 43475.31,
		"low": 43460.2,
		"close": 43472.7,
		"Volume BTC": 0.0946339,
		"Volume USD": 4113.99114453
	},
	{
		"unix": 1646096940,
		"date": "2022-03-01 01:09:00",
		"symbol": "BTC/USD",
		"open": 43508.2,
		"high": 43510.11,
		"low": 43466.6,
		"close": 43486.6,
		"Volume BTC": 1.30280017,
		"Volume USD": 56654.349872722
	},
	{
		"unix": 1646096880,
		"date": "2022-03-01 01:08:00",
		"symbol": "BTC/USD",
		"open": 43542.79,
		"high": 43549.62,
		"low": 43470.24,
		"close": 43470.73,
		"Volume BTC": 2.01266097,
		"Volume USD": 87491.84160840811
	},
	{
		"unix": 1646096820,
		"date": "2022-03-01 01:07:00",
		"symbol": "BTC/USD",
		"open": 43563.63,
		"high": 43564.83,
		"low": 43534.52,
		"close": 43534.52,
		"Volume BTC": 0.58615,
		"Volume USD": 25517.758897999996
	},
	{
		"unix": 1646096760,
		"date": "2022-03-01 01:06:00",
		"symbol": "BTC/USD",
		"open": 43550.42,
		"high": 43565.28,
		"low": 43540.28,
		"close": 43563.63,
		"Volume BTC": 0.18696603,
		"Volume USD": 8144.9189534889
	},
	{
		"unix": 1646096700,
		"date": "2022-03-01 01:05:00",
		"symbol": "BTC/USD",
		"open": 43542.05,
		"high": 43543.32,
		"low": 43529.62,
		"close": 43529.62,
		"Volume BTC": 0.09838068,
		"Volume USD": 4282.4736157416
	},
	{
		"unix": 1646096640,
		"date": "2022-03-01 01:04:00",
		"symbol": "BTC/USD",
		"open": 43571.39,
		"high": 43571.39,
		"low": 43507.51,
		"close": 43544.22,
		"Volume BTC": 1.64525328,
		"Volume USD": 71641.2707800416
	},
	{
		"unix": 1646096580,
		"date": "2022-03-01 01:03:00",
		"symbol": "BTC/USD",
		"open": 43621.64,
		"high": 43621.64,
		"low": 43569.19,
		"close": 43569.19,
		"Volume BTC": 3.4968501,
		"Volume USD": 152354.926408419
	},
	{
		"unix": 1646096520,
		"date": "2022-03-01 01:02:00",
		"symbol": "BTC/USD",
		"open": 43608.84,
		"high": 43667.86,
		"low": 43602.5,
		"close": 43602.5,
		"Volume BTC": 1.61802322,
		"Volume USD": 70549.85745005
	},
	{
		"unix": 1646096460,
		"date": "2022-03-01 01:01:00",
		"symbol": "BTC/USD",
		"open": 43563.73,
		"high": 43567.93,
		"low": 43563.73,
		"close": 43567.93,
		"Volume BTC": 0.01213,
		"Volume USD": 528.4789909
	},
	{
		"unix": 1646096400,
		"date": "2022-03-01 01:00:00",
		"symbol": "BTC/USD",
		"open": 43594.14,
		"high": 43594.14,
		"low": 43530.98,
		"close": 43548.62,
		"Volume BTC": 0.12428802,
		"Volume USD": 5412.5717535324
	},
	{
		"unix": 1646096340,
		"date": "2022-03-01 00:59:00",
		"symbol": "BTC/USD",
		"open": 43559.57,
		"high": 43589.44,
		"low": 43559.57,
		"close": 43589.44,
		"Volume BTC": 0.07165547,
		"Volume USD": 3123.4218102368
	},
	{
		"unix": 1646096280,
		"date": "2022-03-01 00:58:00",
		"symbol": "BTC/USD",
		"open": 43532.28,
		"high": 43596.39,
		"low": 43532.28,
		"close": 43579.53,
		"Volume BTC": 1.8359027,
		"Volume USD": 80007.776791731
	},
	{
		"unix": 1646096220,
		"date": "2022-03-01 00:57:00",
		"symbol": "BTC/USD",
		"open": 43561.53,
		"high": 43569.53,
		"low": 43516.1,
		"close": 43534.16,
		"Volume BTC": 2.11019479,
		"Volume USD": 91865.55761902641
	},
	{
		"unix": 1646096160,
		"date": "2022-03-01 00:56:00",
		"symbol": "BTC/USD",
		"open": 43580.73,
		"high": 43581.13,
		"low": 43533.18,
		"close": 43577.73,
		"Volume BTC": 1.34789756,
		"Volume USD": 58738.315937338804
	},
	{
		"unix": 1646096100,
		"date": "2022-03-01 00:55:00",
		"symbol": "BTC/USD",
		"open": 43509.94,
		"high": 43593.24,
		"low": 43502.01,
		"close": 43569.47,
		"Volume BTC": 3.42198477,
		"Volume USD": 149094.0627769719
	},
	{
		"unix": 1646096040,
		"date": "2022-03-01 00:54:00",
		"symbol": "BTC/USD",
		"open": 43486.15,
		"high": 43574.64,
		"low": 43478.64,
		"close": 43515.56,
		"Volume BTC": 31.47514651,
		"Volume USD": 1369658.6264646954
	},
	{
		"unix": 1646095980,
		"date": "2022-03-01 00:53:00",
		"symbol": "BTC/USD",
		"open": 43436.2,
		"high": 43502.75,
		"low": 43436.2,
		"close": 43481.28,
		"Volume BTC": 38.40282113,
		"Volume USD": 1669803.8183434464
	},
	{
		"unix": 1646095920,
		"date": "2022-03-01 00:52:00",
		"symbol": "BTC/USD",
		"open": 43441.39,
		"high": 43456.66,
		"low": 43425.03,
		"close": 43450.68,
		"Volume BTC": 7.31346647,
		"Volume USD": 317775.0912786996
	},
	{
		"unix": 1646095860,
		"date": "2022-03-01 00:51:00",
		"symbol": "BTC/USD",
		"open": 43429.98,
		"high": 43429.98,
		"low": 43408.85,
		"close": 43408.85,
		"Volume BTC": 0.01157868,
		"Volume USD": 502.61718331799995
	},
	{
		"unix": 1646095800,
		"date": "2022-03-01 00:50:00",
		"symbol": "BTC/USD",
		"open": 43387.38,
		"high": 43414.88,
		"low": 43384.28,
		"close": 43414.88,
		"Volume BTC": 0.02969787,
		"Volume USD": 1289.3294623056
	},
	{
		"unix": 1646095740,
		"date": "2022-03-01 00:49:00",
		"symbol": "BTC/USD",
		"open": 43372.51,
		"high": 43386.97,
		"low": 43331.7,
		"close": 43386.97,
		"Volume BTC": 1.44967028,
		"Volume USD": 62896.800948251606
	},
	{
		"unix": 1646095680,
		"date": "2022-03-01 00:48:00",
		"symbol": "BTC/USD",
		"open": 43450,
		"high": 43455.5,
		"low": 43377.67,
		"close": 43377.67,
		"Volume BTC": 1.46254338,
		"Volume USD": 63441.7240983246
	},
	{
		"unix": 1646095620,
		"date": "2022-03-01 00:47:00",
		"symbol": "BTC/USD",
		"open": 43562.06,
		"high": 43572.23,
		"low": 43463.65,
		"close": 43499.81,
		"Volume BTC": 1.02175064,
		"Volume USD": 44445.9587073784
	},
	{
		"unix": 1646095560,
		"date": "2022-03-01 00:46:00",
		"symbol": "BTC/USD",
		"open": 43501.82,
		"high": 43750,
		"low": 43501.82,
		"close": 43562.06,
		"Volume BTC": 26.03784814,
		"Volume USD": 1134262.3029455685
	},
	{
		"unix": 1646095500,
		"date": "2022-03-01 00:45:00",
		"symbol": "BTC/USD",
		"open": 43531.74,
		"high": 43531.74,
		"low": 43493.21,
		"close": 43501.83,
		"Volume BTC": 0.13318064,
		"Volume USD": 5793.6015605712
	},
	{
		"unix": 1646095440,
		"date": "2022-03-01 00:44:00",
		"symbol": "BTC/USD",
		"open": 43501.21,
		"high": 43521.62,
		"low": 43501.21,
		"close": 43521.62,
		"Volume BTC": 0.08045,
		"Volume USD": 3501.314329
	},
	{
		"unix": 1646095380,
		"date": "2022-03-01 00:43:00",
		"symbol": "BTC/USD",
		"open": 43466.32,
		"high": 43499.09,
		"low": 43461.08,
		"close": 43480,
		"Volume BTC": 3.05148246,
		"Volume USD": 132678.4573608
	},
	{
		"unix": 1646095320,
		"date": "2022-03-01 00:42:00",
		"symbol": "BTC/USD",
		"open": 43499.57,
		"high": 43499.57,
		"low": 43466,
		"close": 43481.94,
		"Volume BTC": 0.01333094,
		"Volume USD": 579.6551332236
	},
	{
		"unix": 1646095260,
		"date": "2022-03-01 00:41:00",
		"symbol": "BTC/USD",
		"open": 43495.77,
		"high": 43513.18,
		"low": 43490.3,
		"close": 43490.3,
		"Volume BTC": 0.01461,
		"Volume USD": 635.393283
	},
	{
		"unix": 1646095200,
		"date": "2022-03-01 00:40:00",
		"symbol": "BTC/USD",
		"open": 43466.24,
		"high": 43481.54,
		"low": 43445.01,
		"close": 43461.65,
		"Volume BTC": 0.02640418,
		"Volume USD": 1147.569229697
	},
	{
		"unix": 1646095140,
		"date": "2022-03-01 00:39:00",
		"symbol": "BTC/USD",
		"open": 43454.83,
		"high": 43473.24,
		"low": 43419,
		"close": 43467.69,
		"Volume BTC": 0.49794774,
		"Volume USD": 21644.637998520604
	},
	{
		"unix": 1646095080,
		"date": "2022-03-01 00:38:00",
		"symbol": "BTC/USD",
		"open": 43474.32,
		"high": 43474.32,
		"low": 43428.4,
		"close": 43428.4,
		"Volume BTC": 0.14002096,
		"Volume USD": 6080.886259264
	},
	{
		"unix": 1646095020,
		"date": "2022-03-01 00:37:00",
		"symbol": "BTC/USD",
		"open": 43485.64,
		"high": 43511.5,
		"low": 43485.64,
		"close": 43511.5,
		"Volume BTC": 0.27341,
		"Volume USD": 11896.479215
	},
	{
		"unix": 1646094960,
		"date": "2022-03-01 00:36:00",
		"symbol": "BTC/USD",
		"open": 43540.61,
		"high": 43569.63,
		"low": 43456.74,
		"close": 43480.19,
		"Volume BTC": 0.34663577,
		"Volume USD": 15071.7891403963
	},
	{
		"unix": 1646094900,
		"date": "2022-03-01 00:35:00",
		"symbol": "BTC/USD",
		"open": 43556.6,
		"high": 43556.6,
		"low": 43540.61,
		"close": 43540.61,
		"Volume BTC": 0.09029185,
		"Volume USD": 3931.3622270285005
	},
	{
		"unix": 1646094840,
		"date": "2022-03-01 00:34:00",
		"symbol": "BTC/USD",
		"open": 43460.77,
		"high": 43531.16,
		"low": 43457.12,
		"close": 43531.16,
		"Volume BTC": 0.28048155,
		"Volume USD": 12209.687230098001
	},
	{
		"unix": 1646094780,
		"date": "2022-03-01 00:33:00",
		"symbol": "BTC/USD",
		"open": 43352.42,
		"high": 43435.86,
		"low": 43352.42,
		"close": 43435.86,
		"Volume BTC": 0.40615638,
		"Volume USD": 17641.751659786798
	},
	{
		"unix": 1646094720,
		"date": "2022-03-01 00:32:00",
		"symbol": "BTC/USD",
		"open": 43347.66,
		"high": 43367.05,
		"low": 43339.69,
		"close": 43357.03,
		"Volume BTC": 0.15747059,
		"Volume USD": 6827.4570947477
	},
	{
		"unix": 1646094660,
		"date": "2022-03-01 00:31:00",
		"symbol": "BTC/USD",
		"open": 43355.53,
		"high": 43385.67,
		"low": 43349.67,
		"close": 43350.54,
		"Volume BTC": 1.09186339,
		"Volume USD": 47332.86756273061
	},
	{
		"unix": 1646094600,
		"date": "2022-03-01 00:30:00",
		"symbol": "BTC/USD",
		"open": 43405.92,
		"high": 43406.48,
		"low": 43372.49,
		"close": 43406.48,
		"Volume BTC": 0.01205452,
		"Volume USD": 523.2442812896
	},
	{
		"unix": 1646094540,
		"date": "2022-03-01 00:29:00",
		"symbol": "BTC/USD",
		"open": 43421.09,
		"high": 43425.38,
		"low": 43390.22,
		"close": 43404.01,
		"Volume BTC": 0.13438,
		"Volume USD": 5832.6308638
	},
	{
		"unix": 1646094480,
		"date": "2022-03-01 00:28:00",
		"symbol": "BTC/USD",
		"open": 43372.49,
		"high": 43402.02,
		"low": 43372.49,
		"close": 43398.14,
		"Volume BTC": 0.0366486,
		"Volume USD": 1590.4810736040001
	},
	{
		"unix": 1646094420,
		"date": "2022-03-01 00:27:00",
		"symbol": "BTC/USD",
		"open": 43385.26,
		"high": 43385.26,
		"low": 43385.26,
		"close": 43385.26,
		"Volume BTC": 0,
		"Volume USD": 0
	},
	{
		"unix": 1646094360,
		"date": "2022-03-01 00:26:00",
		"symbol": "BTC/USD",
		"open": 43331.58,
		"high": 43385.26,
		"low": 43321.39,
		"close": 43385.26,
		"Volume BTC": 0.7897694,
		"Volume USD": 34264.350759044
	},
	{
		"unix": 1646094300,
		"date": "2022-03-01 00:25:00",
		"symbol": "BTC/USD",
		"open": 43323.32,
		"high": 43323.32,
		"low": 43281.53,
		"close": 43281.53,
		"Volume BTC": 0.09273329,
		"Volume USD": 4013.6386731336997
	},
	{
		"unix": 1646094240,
		"date": "2022-03-01 00:24:00",
		"symbol": "BTC/USD",
		"open": 43272.74,
		"high": 43323.33,
		"low": 43264.1,
		"close": 43323.33,
		"Volume BTC": 0.28329777,
		"Volume USD": 12273.4027779741
	},
	{
		"unix": 1646094180,
		"date": "2022-03-01 00:23:00",
		"symbol": "BTC/USD",
		"open": 43297.04,
		"high": 43300.88,
		"low": 43253.51,
		"close": 43253.51,
		"Volume BTC": 0.91598508,
		"Volume USD": 39619.5698176308
	},
	{
		"unix": 1646094120,
		"date": "2022-03-01 00:22:00",
		"symbol": "BTC/USD",
		"open": 43302.24,
		"high": 43322.6,
		"low": 43298.71,
		"close": 43298.71,
		"Volume BTC": 1.53315084,
		"Volume USD": 66383.4536074164
	},
	{
		"unix": 1646094060,
		"date": "2022-03-01 00:21:00",
		"symbol": "BTC/USD",
		"open": 43334.14,
		"high": 43334.14,
		"low": 43254.66,
		"close": 43319.87,
		"Volume BTC": 0.26862322,
		"Volume USD": 11636.7229693814
	},
	{
		"unix": 1646094000,
		"date": "2022-03-01 00:20:00",
		"symbol": "BTC/USD",
		"open": 43277.09,
		"high": 43312.1,
		"low": 43277.09,
		"close": 43312.1,
		"Volume BTC": 0.06704974,
		"Volume USD": 2904.0650438539997
	},
	{
		"unix": 1646093940,
		"date": "2022-03-01 00:19:00",
		"symbol": "BTC/USD",
		"open": 43225.4,
		"high": 43313.25,
		"low": 43225.4,
		"close": 43313.25,
		"Volume BTC": 0.00397465,
		"Volume USD": 172.15500911249998
	},
	{
		"unix": 1646093880,
		"date": "2022-03-01 00:18:00",
		"symbol": "BTC/USD",
		"open": 43288.55,
		"high": 43294.25,
		"low": 43185.48,
		"close": 43185.48,
		"Volume BTC": 0.03572912,
		"Volume USD": 1542.9791971776003
	},
	{
		"unix": 1646093820,
		"date": "2022-03-01 00:17:00",
		"symbol": "BTC/USD",
		"open": 43295.99,
		"high": 43295.99,
		"low": 43289.94,
		"close": 43289.94,
		"Volume BTC": 0.00246,
		"Volume USD": 106.4932524
	},
	{
		"unix": 1646093760,
		"date": "2022-03-01 00:16:00",
		"symbol": "BTC/USD",
		"open": 43347.01,
		"high": 43347.01,
		"low": 43294.06,
		"close": 43320.88,
		"Volume BTC": 1.76863419,
		"Volume USD": 76618.78950888719
	},
	{
		"unix": 1646093700,
		"date": "2022-03-01 00:15:00",
		"symbol": "BTC/USD",
		"open": 43372.55,
		"high": 43388.79,
		"low": 43340.55,
		"close": 43340.55,
		"Volume BTC": 0.11601,
		"Volume USD": 5027.9372055
	},
	{
		"unix": 1646093640,
		"date": "2022-03-01 00:14:00",
		"symbol": "BTC/USD",
		"open": 43417.59,
		"high": 43417.59,
		"low": 43378.35,
		"close": 43411.41,
		"Volume BTC": 0.13698,
		"Volume USD": 5946.4949418
	},
	{
		"unix": 1646093580,
		"date": "2022-03-01 00:13:00",
		"symbol": "BTC/USD",
		"open": 43500,
		"high": 43500,
		"low": 43427.11,
		"close": 43427.11,
		"Volume BTC": 0.06172,
		"Volume USD": 2680.3212292
	},
	{
		"unix": 1646093520,
		"date": "2022-03-01 00:12:00",
		"symbol": "BTC/USD",
		"open": 43605.16,
		"high": 43605.16,
		"low": 43497.34,
		"close": 43519.6,
		"Volume BTC": 0.07608679,
		"Volume USD": 3311.266666084
	},
	{
		"unix": 1646093460,
		"date": "2022-03-01 00:11:00",
		"symbol": "BTC/USD",
		"open": 43522.22,
		"high": 43626.49,
		"low": 43455.53,
		"close": 43548.01,
		"Volume BTC": 7.67421053,
		"Volume USD": 334196.5969025453
	},
	{
		"unix": 1646093400,
		"date": "2022-03-01 00:10:00",
		"symbol": "BTC/USD",
		"open": 43474.74,
		"high": 43524.89,
		"low": 43473.63,
		"close": 43495.8,
		"Volume BTC": 2.1376409,
		"Volume USD": 92978.40105822001
	},
	{
		"unix": 1646093340,
		"date": "2022-03-01 00:09:00",
		"symbol": "BTC/USD",
		"open": 43420.93,
		"high": 43491.05,
		"low": 43405.99,
		"close": 43476.43,
		"Volume BTC": 6.82982072,
		"Volume USD": 296936.2224456296
	},
	{
		"unix": 1646093280,
		"date": "2022-03-01 00:08:00",
		"symbol": "BTC/USD",
		"open": 43350.94,
		"high": 43416.92,
		"low": 43350,
		"close": 43416.92,
		"Volume BTC": 2.99604793,
		"Volume USD": 130079.1732929756
	},
	{
		"unix": 1646093220,
		"date": "2022-03-01 00:07:00",
		"symbol": "BTC/USD",
		"open": 43332.82,
		"high": 43337.33,
		"low": 43234.18,
		"close": 43337.33,
		"Volume BTC": 0.08684714,
		"Volume USD": 3763.7231657362004
	},
	{
		"unix": 1646093160,
		"date": "2022-03-01 00:06:00",
		"symbol": "BTC/USD",
		"open": 43367.21,
		"high": 43383.79,
		"low": 43312.28,
		"close": 43312.28,
		"Volume BTC": 0.08835734,
		"Volume USD": 3826.9578501352003
	},
	{
		"unix": 1646093100,
		"date": "2022-03-01 00:05:00",
		"symbol": "BTC/USD",
		"open": 43422.84,
		"high": 43459.86,
		"low": 43376.9,
		"close": 43414.23,
		"Volume BTC": 8.15355029,
		"Volume USD": 353980.10760662676
	},
	{
		"unix": 1646093040,
		"date": "2022-03-01 00:04:00",
		"symbol": "BTC/USD",
		"open": 43382.22,
		"high": 43521.03,
		"low": 43382.2,
		"close": 43451.47,
		"Volume BTC": 6.05616835,
		"Volume USD": 263149.4173749745
	},
	{
		"unix": 1646092980,
		"date": "2022-03-01 00:03:00",
		"symbol": "BTC/USD",
		"open": 43510.9,
		"high": 43510.9,
		"low": 43414.03,
		"close": 43435.2,
		"Volume BTC": 0.11482569,
		"Volume USD": 4987.476810288
	},
	{
		"unix": 1646092920,
		"date": "2022-03-01 00:02:00",
		"symbol": "BTC/USD",
		"open": 43297.23,
		"high": 43555.63,
		"low": 43297.03,
		"close": 43555.63,
		"Volume BTC": 11.88633453,
		"Volume USD": 517716.7888449038
	},
	{
		"unix": 1646092860,
		"date": "2022-03-01 00:01:00",
		"symbol": "BTC/USD",
		"open": 43193.86,
		"high": 43259,
		"low": 43193.86,
		"close": 43243.98,
		"Volume BTC": 0.47922203,
		"Volume USD": 20723.4678808794
	},
	{
		"unix": 1646092800,
		"date": "2022-03-01 00:00:00",
		"symbol": "BTC/USD",
		"open": 43221.71,
		"high": 43244.68,
		"low": 43214.95,
		"close": 43237.6,
		"Volume BTC": 0.30564315,
		"Volume USD": 13215.276262439998
	},
	{
		"unix": 1646092740,
		"date": "2022-02-28 23:59:00",
		"symbol": "BTC/USD",
		"open": 43241.86,
		"high": 43241.86,
		"low": 43178.98,
		"close": 43178.98,
		"Volume BTC": 1.19487806,
		"Volume USD": 51593.6158551788
	},
	{
		"unix": 1646092680,
		"date": "2022-02-28 23:58:00",
		"symbol": "BTC/USD",
		"open": 43203.23,
		"high": 43244.06,
		"low": 43202.61,
		"close": 43244.06,
		"Volume BTC": 2.0070876,
		"Volume USD": 86794.616599656
	},
	{
		"unix": 1646092620,
		"date": "2022-02-28 23:57:00",
		"symbol": "BTC/USD",
		"open": 43189.84,
		"high": 43193.61,
		"low": 43189.84,
		"close": 43190.96,
		"Volume BTC": 0.24732027,
		"Volume USD": 10681.999888759201
	},
	{
		"unix": 1646092560,
		"date": "2022-02-28 23:56:00",
		"symbol": "BTC/USD",
		"open": 43207.7,
		"high": 43207.7,
		"low": 43157.28,
		"close": 43180,
		"Volume BTC": 1.23434758,
		"Volume USD": 53299.1285044
	},
	{
		"unix": 1646092500,
		"date": "2022-02-28 23:55:00",
		"symbol": "BTC/USD",
		"open": 43226.17,
		"high": 43251.16,
		"low": 43223.06,
		"close": 43223.06,
		"Volume BTC": 0.44052568,
		"Volume USD": 19040.867898180797
	},
	{
		"unix": 1646092440,
		"date": "2022-02-28 23:54:00",
		"symbol": "BTC/USD",
		"open": 43197.31,
		"high": 43231.87,
		"low": 43197.3,
		"close": 43197.3,
		"Volume BTC": 0.17117683,
		"Volume USD": 7394.376878559001
	},
	{
		"unix": 1646092380,
		"date": "2022-02-28 23:53:00",
		"symbol": "BTC/USD",
		"open": 43233.42,
		"high": 43250.84,
		"low": 43212.33,
		"close": 43214.19,
		"Volume BTC": 0.66945843,
		"Volume USD": 28930.103791121703
	},
	{
		"unix": 1646092320,
		"date": "2022-02-28 23:52:00",
		"symbol": "BTC/USD",
		"open": 43260.7,
		"high": 43274.26,
		"low": 43229.99,
		"close": 43252.98,
		"Volume BTC": 1.30524124,
		"Volume USD": 56455.573248895205
	},
	{
		"unix": 1646092260,
		"date": "2022-02-28 23:51:00",
		"symbol": "BTC/USD",
		"open": 43267.57,
		"high": 43295.27,
		"low": 43243.78,
		"close": 43260.69,
		"Volume BTC": 0.73894926,
		"Volume USD": 31967.454862589402
	},
	{
		"unix": 1646092200,
		"date": "2022-02-28 23:50:00",
		"symbol": "BTC/USD",
		"open": 43268.03,
		"high": 43275.6,
		"low": 43261.1,
		"close": 43263.75,
		"Volume BTC": 0.11230423,
		"Volume USD": 4858.7021306625
	},
	{
		"unix": 1646092140,
		"date": "2022-02-28 23:49:00",
		"symbol": "BTC/USD",
		"open": 43207.87,
		"high": 43247.11,
		"low": 43207.87,
		"close": 43247.11,
		"Volume BTC": 0.81321421,
		"Volume USD": 35169.1643934331
	},
	{
		"unix": 1646092080,
		"date": "2022-02-28 23:48:00",
		"symbol": "BTC/USD",
		"open": 43163.62,
		"high": 43207.87,
		"low": 43163.62,
		"close": 43207.87,
		"Volume BTC": 0.56129347,
		"Volume USD": 24252.2952836089
	},
	{
		"unix": 1646092020,
		"date": "2022-02-28 23:47:00",
		"symbol": "BTC/USD",
		"open": 43219.65,
		"high": 43229.59,
		"low": 43192.94,
		"close": 43192.94,
		"Volume BTC": 0.44786904,
		"Volume USD": 19344.7805725776
	},
	{
		"unix": 1646091960,
		"date": "2022-02-28 23:46:00",
		"symbol": "BTC/USD",
		"open": 43214.72,
		"high": 43251.34,
		"low": 43196.05,
		"close": 43251.34,
		"Volume BTC": 0.25946985,
		"Volume USD": 11222.418702099
	},
	{
		"unix": 1646091900,
		"date": "2022-02-28 23:45:00",
		"symbol": "BTC/USD",
		"open": 43202.73,
		"high": 43272.78,
		"low": 43155.71,
		"close": 43261.32,
		"Volume BTC": 0.51607161,
		"Volume USD": 22325.9390631252
	},
	{
		"unix": 1646091840,
		"date": "2022-02-28 23:44:00",
		"symbol": "BTC/USD",
		"open": 43185,
		"high": 43202.73,
		"low": 43185,
		"close": 43202.73,
		"Volume BTC": 0.48075276,
		"Volume USD": 20769.831687034803
	},
	{
		"unix": 1646091780,
		"date": "2022-02-28 23:43:00",
		"symbol": "BTC/USD",
		"open": 43225.1,
		"high": 43245.12,
		"low": 43185,
		"close": 43185,
		"Volume BTC": 0.50815834,
		"Volume USD": 21944.817912900002
	},
	{
		"unix": 1646091720,
		"date": "2022-02-28 23:42:00",
		"symbol": "BTC/USD",
		"open": 43209.57,
		"high": 43232.54,
		"low": 43209.56,
		"close": 43232.54,
		"Volume BTC": 3.17024403,
		"Volume USD": 137057.7018367362
	},
	{
		"unix": 1646091660,
		"date": "2022-02-28 23:41:00",
		"symbol": "BTC/USD",
		"open": 43213.13,
		"high": 43213.13,
		"low": 43201.17,
		"close": 43201.17,
		"Volume BTC": 0.47551,
		"Volume USD": 20542.5883467
	},
	{
		"unix": 1646091600,
		"date": "2022-02-28 23:40:00",
		"symbol": "BTC/USD",
		"open": 43255.85,
		"high": 43255.85,
		"low": 43195,
		"close": 43230.53,
		"Volume BTC": 2.37559869,
		"Volume USD": 102698.39043600569
	},
	{
		"unix": 1646091540,
		"date": "2022-02-28 23:39:00",
		"symbol": "BTC/USD",
		"open": 43325.47,
		"high": 43325.47,
		"low": 43261.22,
		"close": 43291.36,
		"Volume BTC": 0.18082,
		"Volume USD": 7827.9437152
	},
	{
		"unix": 1646091480,
		"date": "2022-02-28 23:38:00",
		"symbol": "BTC/USD",
		"open": 43236.18,
		"high": 43292.45,
		"low": 43236.18,
		"close": 43292.45,
		"Volume BTC": 0.08491531,
		"Volume USD": 3676.1918124094996
	},
	{
		"unix": 1646091420,
		"date": "2022-02-28 23:37:00",
		"symbol": "BTC/USD",
		"open": 43203.87,
		"high": 43288.88,
		"low": 43203.87,
		"close": 43240.47,
		"Volume BTC": 0.77702064,
		"Volume USD": 33598.737673300806
	},
	{
		"unix": 1646091360,
		"date": "2022-02-28 23:36:00",
		"symbol": "BTC/USD",
		"open": 43164.64,
		"high": 43198.37,
		"low": 43141.62,
		"close": 43157.58,
		"Volume BTC": 2.29150113,
		"Volume USD": 98895.6433380654
	},
	{
		"unix": 1646091300,
		"date": "2022-02-28 23:35:00",
		"symbol": "BTC/USD",
		"open": 43179.06,
		"high": 43198.17,
		"low": 43141.87,
		"close": 43182.36,
		"Volume BTC": 0.00856,
		"Volume USD": 369.6410016
	},
	{
		"unix": 1646091240,
		"date": "2022-02-28 23:34:00",
		"symbol": "BTC/USD",
		"open": 43183.92,
		"high": 43184.6,
		"low": 43152.73,
		"close": 43173.48,
		"Volume BTC": 0.99880669,
		"Volume USD": 43121.960654581206
	},
	{
		"unix": 1646091180,
		"date": "2022-02-28 23:33:00",
		"symbol": "BTC/USD",
		"open": 43060.1,
		"high": 43209.71,
		"low": 43058.05,
		"close": 43191.34,
		"Volume BTC": 1.37110962,
		"Volume USD": 59220.06177469079
	},
	{
		"unix": 1646091120,
		"date": "2022-02-28 23:32:00",
		"symbol": "BTC/USD",
		"open": 43003.53,
		"high": 43105.55,
		"low": 43003.53,
		"close": 43105.55,
		"Volume BTC": 0.49495446,
		"Volume USD": 21335.284223253002
	},
	{
		"unix": 1646091060,
		"date": "2022-02-28 23:31:00",
		"symbol": "BTC/USD",
		"open": 43072.33,
		"high": 43121.3,
		"low": 43000,
		"close": 43000,
		"Volume BTC": 6.22610138,
		"Volume USD": 267722.35934
	},
	{
		"unix": 1646091000,
		"date": "2022-02-28 23:30:00",
		"symbol": "BTC/USD",
		"open": 43103.95,
		"high": 43137.82,
		"low": 43072.29,
		"close": 43072.32,
		"Volume BTC": 2.54351002,
		"Volume USD": 109554.87750464639
	},
	{
		"unix": 1646090940,
		"date": "2022-02-28 23:29:00",
		"symbol": "BTC/USD",
		"open": 43170.7,
		"high": 43174.6,
		"low": 43122.28,
		"close": 43135.7,
		"Volume BTC": 1.61862963,
		"Volume USD": 69820.722130791
	},
	{
		"unix": 1646090880,
		"date": "2022-02-28 23:28:00",
		"symbol": "BTC/USD",
		"open": 43122.72,
		"high": 43142.78,
		"low": 43090.17,
		"close": 43142.78,
		"Volume BTC": 0.2527786,
		"Volume USD": 10905.571528508
	},
	{
		"unix": 1646090820,
		"date": "2022-02-28 23:27:00",
		"symbol": "BTC/USD",
		"open": 43091.17,
		"high": 43191.1,
		"low": 43060.11,
		"close": 43106.49,
		"Volume BTC": 3.9055039,
		"Volume USD": 168352.56481031099
	},
	{
		"unix": 1646090760,
		"date": "2022-02-28 23:26:00",
		"symbol": "BTC/USD",
		"open": 42926.12,
		"high": 43072.64,
		"low": 42900.28,
		"close": 43072.64,
		"Volume BTC": 1.95639007,
		"Volume USD": 84266.8851846848
	},
	{
		"unix": 1646090700,
		"date": "2022-02-28 23:25:00",
		"symbol": "BTC/USD",
		"open": 42940.94,
		"high": 42970.36,
		"low": 42900,
		"close": 42959.52,
		"Volume BTC": 0.52643613,
		"Volume USD": 22615.4434554576
	},
	{
		"unix": 1646090640,
		"date": "2022-02-28 23:24:00",
		"symbol": "BTC/USD",
		"open": 42949.61,
		"high": 42964.59,
		"low": 42902.95,
		"close": 42940.11,
		"Volume BTC": 1.45053289,
		"Volume USD": 62286.0418552179
	},
	{
		"unix": 1646090580,
		"date": "2022-02-28 23:23:00",
		"symbol": "BTC/USD",
		"open": 42997.92,
		"high": 42997.92,
		"low": 42920.54,
		"close": 42976,
		"Volume BTC": 1.57905154,
		"Volume USD": 67861.31898304001
	},
	{
		"unix": 1646090520,
		"date": "2022-02-28 23:22:00",
		"symbol": "BTC/USD",
		"open": 43031.83,
		"high": 43080.74,
		"low": 42990.77,
		"close": 43019.93,
		"Volume BTC": 5.39904623,
		"Volume USD": 232266.5908813639
	},
	{
		"unix": 1646090460,
		"date": "2022-02-28 23:21:00",
		"symbol": "BTC/USD",
		"open": 43038.68,
		"high": 43038.68,
		"low": 42980.38,
		"close": 43025.34,
		"Volume BTC": 0.73882971,
		"Volume USD": 31788.399474851398
	},
	{
		"unix": 1646090400,
		"date": "2022-02-28 23:20:00",
		"symbol": "BTC/USD",
		"open": 42994.09,
		"high": 43093.61,
		"low": 42994.09,
		"close": 43025.61,
		"Volume BTC": 1.60285709,
		"Volume USD": 68963.9040400749
	},
	{
		"unix": 1646090340,
		"date": "2022-02-28 23:19:00",
		"symbol": "BTC/USD",
		"open": 43079.68,
		"high": 43079.68,
		"low": 42892.37,
		"close": 42937.91,
		"Volume BTC": 5.62172057,
		"Volume USD": 241384.93187980872
	},
	{
		"unix": 1646090280,
		"date": "2022-02-28 23:18:00",
		"symbol": "BTC/USD",
		"open": 43091.24,
		"high": 43117.18,
		"low": 43050,
		"close": 43050,
		"Volume BTC": 0.21365722,
		"Volume USD": 9197.943321
	},
	{
		"unix": 1646090220,
		"date": "2022-02-28 23:17:00",
		"symbol": "BTC/USD",
		"open": 43149.82,
		"high": 43187.35,
		"low": 43099.41,
		"close": 43104.12,
		"Volume BTC": 2.78689175,
		"Volume USD": 120126.51641901002
	},
	{
		"unix": 1646090160,
		"date": "2022-02-28 23:16:00",
		"symbol": "BTC/USD",
		"open": 43212.03,
		"high": 43216.28,
		"low": 43149.25,
		"close": 43167.51,
		"Volume BTC": 6.07374777,
		"Volume USD": 262188.5675989527
	},
	{
		"unix": 1646090100,
		"date": "2022-02-28 23:15:00",
		"symbol": "BTC/USD",
		"open": 43292.87,
		"high": 43364.81,
		"low": 43193.92,
		"close": 43193.92,
		"Volume BTC": 14.81208536,
		"Volume USD": 639792.0300730112
	},
	{
		"unix": 1646090040,
		"date": "2022-02-28 23:14:00",
		"symbol": "BTC/USD",
		"open": 43261.62,
		"high": 43332.33,
		"low": 43261.62,
		"close": 43300,
		"Volume BTC": 1.51819624,
		"Volume USD": 65737.897192
	},
	{
		"unix": 1646089980,
		"date": "2022-02-28 23:13:00",
		"symbol": "BTC/USD",
		"open": 43305.41,
		"high": 43321.81,
		"low": 43265,
		"close": 43284.91,
		"Volume BTC": 2.50953431,
		"Volume USD": 108624.9667502621
	},
	{
		"unix": 1646089920,
		"date": "2022-02-28 23:12:00",
		"symbol": "BTC/USD",
		"open": 43228.98,
		"high": 43340.25,
		"low": 43179.62,
		"close": 43318.25,
		"Volume BTC": 4.87322081,
		"Volume USD": 211099.39735278251
	},
	{
		"unix": 1646089860,
		"date": "2022-02-28 23:11:00",
		"symbol": "BTC/USD",
		"open": 43209.95,
		"high": 43265.84,
		"low": 43189.76,
		"close": 43244.01,
		"Volume BTC": 0.02020578,
		"Volume USD": 873.7789523778
	},
	{
		"unix": 1646089800,
		"date": "2022-02-28 23:10:00",
		"symbol": "BTC/USD",
		"open": 43200,
		"high": 43233.49,
		"low": 43180.01,
		"close": 43233.49,
		"Volume BTC": 0.17088756,
		"Volume USD": 7388.0656163844
	},
	{
		"unix": 1646089740,
		"date": "2022-02-28 23:09:00",
		"symbol": "BTC/USD",
		"open": 43235.42,
		"high": 43235.42,
		"low": 43148.16,
		"close": 43169.78,
		"Volume BTC": 0.07887358,
		"Volume USD": 3404.9550964124
	},
	{
		"unix": 1646089680,
		"date": "2022-02-28 23:08:00",
		"symbol": "BTC/USD",
		"open": 43296,
		"high": 43296,
		"low": 43227.91,
		"close": 43250,
		"Volume BTC": 2.87796702,
		"Volume USD": 124472.07361499999
	},
	{
		"unix": 1646089620,
		"date": "2022-02-28 23:07:00",
		"symbol": "BTC/USD",
		"open": 43198.84,
		"high": 43262.76,
		"low": 43198.84,
		"close": 43262.76,
		"Volume BTC": 0.09887248,
		"Volume USD": 4277.4963728448
	},
	{
		"unix": 1646089560,
		"date": "2022-02-28 23:06:00",
		"symbol": "BTC/USD",
		"open": 43226,
		"high": 43260.21,
		"low": 43198.36,
		"close": 43209.61,
		"Volume BTC": 0.2274,
		"Volume USD": 9825.865314
	},
	{
		"unix": 1646089500,
		"date": "2022-02-28 23:05:00",
		"symbol": "BTC/USD",
		"open": 43211.54,
		"high": 43240.23,
		"low": 43130.6,
		"close": 43240.23,
		"Volume BTC": 2.24039358,
		"Volume USD": 96875.13368972341
	},
	{
		"unix": 1646089440,
		"date": "2022-02-28 23:04:00",
		"symbol": "BTC/USD",
		"open": 43260.92,
		"high": 43279.38,
		"low": 43158.14,
		"close": 43164.82,
		"Volume BTC": 2.91446347,
		"Volume USD": 125802.2910791254
	},
	{
		"unix": 1646089380,
		"date": "2022-02-28 23:03:00",
		"symbol": "BTC/USD",
		"open": 43166.32,
		"high": 43226.91,
		"low": 43166.32,
		"close": 43167.09,
		"Volume BTC": 0.37509982,
		"Volume USD": 16191.967688923798
	},
	{
		"unix": 1646089320,
		"date": "2022-02-28 23:02:00",
		"symbol": "BTC/USD",
		"open": 43183.72,
		"high": 43203.75,
		"low": 43078.84,
		"close": 43133.31,
		"Volume BTC": 2.32266922,
		"Volume USD": 100184.41149371819
	},
	{
		"unix": 1646089260,
		"date": "2022-02-28 23:01:00",
		"symbol": "BTC/USD",
		"open": 43052.17,
		"high": 43131.74,
		"low": 43042.65,
		"close": 43131.74,
		"Volume BTC": 2.32735358,
		"Volume USD": 100382.8095006292
	},
	{
		"unix": 1646089200,
		"date": "2022-02-28 23:00:00",
		"symbol": "BTC/USD",
		"open": 43085.3,
		"high": 43250,
		"low": 43027.93,
		"close": 43053.74,
		"Volume BTC": 3.01603634,
		"Volume USD": 129851.64441291158
	},
	{
		"unix": 1646089140,
		"date": "2022-02-28 22:59:00",
		"symbol": "BTC/USD",
		"open": 43141.51,
		"high": 43204.34,
		"low": 42847.85,
		"close": 42907.32,
		"Volume BTC": 19.44101661,
		"Volume USD": 834161.9208105851
	},
	{
		"unix": 1646089080,
		"date": "2022-02-28 22:58:00",
		"symbol": "BTC/USD",
		"open": 43209.07,
		"high": 43299.34,
		"low": 43128,
		"close": 43141.49,
		"Volume BTC": 8.57709162,
		"Volume USD": 370028.51235331374
	},
	{
		"unix": 1646089020,
		"date": "2022-02-28 22:57:00",
		"symbol": "BTC/USD",
		"open": 43311.92,
		"high": 43371.93,
		"low": 43243.55,
		"close": 43243.55,
		"Volume BTC": 4.01438394,
		"Volume USD": 173596.212628587
	},
	{
		"unix": 1646088960,
		"date": "2022-02-28 22:56:00",
		"symbol": "BTC/USD",
		"open": 43375.86,
		"high": 43443.64,
		"low": 43277.02,
		"close": 43277.02,
		"Volume BTC": 12.7873212,
		"Volume USD": 553397.1553188239
	},
	{
		"unix": 1646088900,
		"date": "2022-02-28 22:55:00",
		"symbol": "BTC/USD",
		"open": 43343.06,
		"high": 43435.26,
		"low": 43301,
		"close": 43435.26,
		"Volume BTC": 1.85220728,
		"Volume USD": 80451.10478069281
	},
	{
		"unix": 1646088840,
		"date": "2022-02-28 22:54:00",
		"symbol": "BTC/USD",
		"open": 43409.13,
		"high": 43424.98,
		"low": 43312.83,
		"close": 43356.59,
		"Volume BTC": 7.95512191,
		"Volume USD": 344906.95905188686
	},
	{
		"unix": 1646088780,
		"date": "2022-02-28 22:53:00",
		"symbol": "BTC/USD",
		"open": 43501,
		"high": 43568.54,
		"low": 43401,
		"close": 43401,
		"Volume BTC": 9.79455051,
		"Volume USD": 425093.28668451
	},
	{
		"unix": 1646088720,
		"date": "2022-02-28 22:52:00",
		"symbol": "BTC/USD",
		"open": 43637.65,
		"high": 43645.14,
		"low": 43521.11,
		"close": 43521.11,
		"Volume BTC": 3.57907489,
		"Volume USD": 155765.3119859279
	},
	{
		"unix": 1646088660,
		"date": "2022-02-28 22:51:00",
		"symbol": "BTC/USD",
		"open": 43748.2,
		"high": 43752.76,
		"low": 43627.21,
		"close": 43637.65,
		"Volume BTC": 1.87409337,
		"Volume USD": 81781.0305473805
	},
	{
		"unix": 1646088600,
		"date": "2022-02-28 22:50:00",
		"symbol": "BTC/USD",
		"open": 43822.24,
		"high": 43822.24,
		"low": 43730.39,
		"close": 43801.5,
		"Volume BTC": 5.15938821,
		"Volume USD": 225988.94268031503
	},
	{
		"unix": 1646088540,
		"date": "2022-02-28 22:49:00",
		"symbol": "BTC/USD",
		"open": 43725.34,
		"high": 43835.4,
		"low": 43725.34,
		"close": 43829.8,
		"Volume BTC": 2.16458519,
		"Volume USD": 94873.335960662
	},
	{
		"unix": 1646088480,
		"date": "2022-02-28 22:48:00",
		"symbol": "BTC/USD",
		"open": 43772.94,
		"high": 43776,
		"low": 43727.72,
		"close": 43745.86,
		"Volume BTC": 1.3040901,
		"Volume USD": 57048.542941986
	},
	{
		"unix": 1646088420,
		"date": "2022-02-28 22:47:00",
		"symbol": "BTC/USD",
		"open": 43755.49,
		"high": 43904.97,
		"low": 43737.62,
		"close": 43761.22,
		"Volume BTC": 4.61946362,
		"Volume USD": 202153.36375681643
	},
	{
		"unix": 1646088360,
		"date": "2022-02-28 22:46:00",
		"symbol": "BTC/USD",
		"open": 43521.11,
		"high": 43835.4,
		"low": 43521.11,
		"close": 43811.11,
		"Volume BTC": 0.89586139,
		"Volume USD": 39248.6819020429
	},
	{
		"unix": 1646088300,
		"date": "2022-02-28 22:45:00",
		"symbol": "BTC/USD",
		"open": 43631.18,
		"high": 43631.18,
		"low": 43533.2,
		"close": 43533.2,
		"Volume BTC": 5.50861459,
		"Volume USD": 239807.62066938798
	},
	{
		"unix": 1646088240,
		"date": "2022-02-28 22:44:00",
		"symbol": "BTC/USD",
		"open": 43731.41,
		"high": 43731.41,
		"low": 43591.17,
		"close": 43681.6,
		"Volume BTC": 10.5298205,
		"Volume USD": 459959.40715279995
	},
	{
		"unix": 1646088180,
		"date": "2022-02-28 22:43:00",
		"symbol": "BTC/USD",
		"open": 43675.39,
		"high": 43719.94,
		"low": 43631.45,
		"close": 43673.46,
		"Volume BTC": 2.63757551,
		"Volume USD": 115192.0485329646
	},
	{
		"unix": 1646088120,
		"date": "2022-02-28 22:42:00",
		"symbol": "BTC/USD",
		"open": 43752.56,
		"high": 43808,
		"low": 43675.54,
		"close": 43675.55,
		"Volume BTC": 0.92574495,
		"Volume USD": 40432.4198509725
	},
	{
		"unix": 1646088060,
		"date": "2022-02-28 22:41:00",
		"symbol": "BTC/USD",
		"open": 43826.67,
		"high": 44026.73,
		"low": 43625.62,
		"close": 43708.5,
		"Volume BTC": 7.74899098,
		"Volume USD": 338696.77224933
	},
	{
		"unix": 1646088000,
		"date": "2022-02-28 22:40:00",
		"symbol": "BTC/USD",
		"open": 43625.63,
		"high": 43912.26,
		"low": 43625.62,
		"close": 43819.82,
		"Volume BTC": 2.56575464,
		"Volume USD": 112430.90648896481
	},
	{
		"unix": 1646087940,
		"date": "2022-02-28 22:39:00",
		"symbol": "BTC/USD",
		"open": 43819.9,
		"high": 43839.86,
		"low": 43625.62,
		"close": 43646.1,
		"Volume BTC": 6.45561285,
		"Volume USD": 281762.324012385
	},
	{
		"unix": 1646087880,
		"date": "2022-02-28 22:38:00",
		"symbol": "BTC/USD",
		"open": 43901.1,
		"high": 44029.69,
		"low": 43818.76,
		"close": 43869.17,
		"Volume BTC": 5.87063362,
		"Volume USD": 257539.8242834954
	},
	{
		"unix": 1646087820,
		"date": "2022-02-28 22:37:00",
		"symbol": "BTC/USD",
		"open": 43740.36,
		"high": 44256.08,
		"low": 43650.09,
		"close": 44097.13,
		"Volume BTC": 25.49995912,
		"Volume USD": 1124475.0123093256
	},
	{
		"unix": 1646087760,
		"date": "2022-02-28 22:36:00",
		"symbol": "BTC/USD",
		"open": 43673.1,
		"high": 44098.97,
		"low": 43420.14,
		"close": 43811.03,
		"Volume BTC": 38.8390541,
		"Volume USD": 1701578.964346723
	},
	{
		"unix": 1646087700,
		"date": "2022-02-28 22:35:00",
		"symbol": "BTC/USD",
		"open": 43356.44,
		"high": 43844.33,
		"low": 43316.28,
		"close": 43673.14,
		"Volume BTC": 23.69026203,
		"Volume USD": 1034628.1302728741
	},
	{
		"unix": 1646087640,
		"date": "2022-02-28 22:34:00",
		"symbol": "BTC/USD",
		"open": 43351.48,
		"high": 43835.4,
		"low": 42950.74,
		"close": 43499.96,
		"Volume BTC": 76.57016392,
		"Volume USD": 3330799.067713443
	},
	{
		"unix": 1646087580,
		"date": "2022-02-28 22:33:00",
		"symbol": "BTC/USD",
		"open": 42699.99,
		"high": 43532.2,
		"low": 42570.53,
		"close": 43492.48,
		"Volume BTC": 84.20192449,
		"Volume USD": 3662150.516842835
	},
	{
		"unix": 1646087520,
		"date": "2022-02-28 22:32:00",
		"symbol": "BTC/USD",
		"open": 41894.5,
		"high": 42699.99,
		"low": 41860.76,
		"close": 42699.99,
		"Volume BTC": 39.01689127,
		"Volume USD": 1666020.8670600874
	},
	{
		"unix": 1646087460,
		"date": "2022-02-28 22:31:00",
		"symbol": "BTC/USD",
		"open": 41952.01,
		"high": 41952.01,
		"low": 41910.65,
		"close": 41921.79,
		"Volume BTC": 0.26824829,
		"Volume USD": 11245.4484812391
	},
	{
		"unix": 1646087400,
		"date": "2022-02-28 22:30:00",
		"symbol": "BTC/USD",
		"open": 41905.2,
		"high": 41952.22,
		"low": 41890.59,
		"close": 41952.18,
		"Volume BTC": 0.14506677,
		"Volume USD": 6085.867247058601
	},
	{
		"unix": 1646087340,
		"date": "2022-02-28 22:29:00",
		"symbol": "BTC/USD",
		"open": 41878.87,
		"high": 41906.96,
		"low": 41869.83,
		"close": 41869.83,
		"Volume BTC": 1.23790021,
		"Volume USD": 51830.671349664306
	},
	{
		"unix": 1646087280,
		"date": "2022-02-28 22:28:00",
		"symbol": "BTC/USD",
		"open": 41947.9,
		"high": 41947.9,
		"low": 41833.9,
		"close": 41878.86,
		"Volume BTC": 2.63801509,
		"Volume USD": 110477.0646319974
	},
	{
		"unix": 1646087220,
		"date": "2022-02-28 22:27:00",
		"symbol": "BTC/USD",
		"open": 41948.48,
		"high": 41948.48,
		"low": 41915.68,
		"close": 41915.68,
		"Volume BTC": 1.30992502,
		"Volume USD": 54906.397962313604
	},
	{
		"unix": 1646087160,
		"date": "2022-02-28 22:26:00",
		"symbol": "BTC/USD",
		"open": 41853.98,
		"high": 41886.03,
		"low": 41853.98,
		"close": 41877,
		"Volume BTC": 1.6672243,
		"Volume USD": 69818.3520111
	},
	{
		"unix": 1646087100,
		"date": "2022-02-28 22:25:00",
		"symbol": "BTC/USD",
		"open": 41900.3,
		"high": 41900.3,
		"low": 41826.7,
		"close": 41826.7,
		"Volume BTC": 1.19779528,
		"Volume USD": 50099.823837976
	},
	{
		"unix": 1646087040,
		"date": "2022-02-28 22:24:00",
		"symbol": "BTC/USD",
		"open": 41958.69,
		"high": 41958.69,
		"low": 41901.1,
		"close": 41901.1,
		"Volume BTC": 20.28807586,
		"Volume USD": 850092.695417446
	},
	{
		"unix": 1646086980,
		"date": "2022-02-28 22:23:00",
		"symbol": "BTC/USD",
		"open": 41906.33,
		"high": 42171.16,
		"low": 41893.61,
		"close": 41964.59,
		"Volume BTC": 60.90170222,
		"Volume USD": 2555714.9639643896
	},
	{
		"unix": 1646086920,
		"date": "2022-02-28 22:22:00",
		"symbol": "BTC/USD",
		"open": 41832.69,
		"high": 41965.95,
		"low": 41832.69,
		"close": 41965.95,
		"Volume BTC": 1.21231777,
		"Volume USD": 50876.0669199315
	},
	{
		"unix": 1646086860,
		"date": "2022-02-28 22:21:00",
		"symbol": "BTC/USD",
		"open": 41824.6,
		"high": 41858.78,
		"low": 41799.97,
		"close": 41858.77,
		"Volume BTC": 1.16175558,
		"Volume USD": 48629.65961943659
	},
	{
		"unix": 1646086800,
		"date": "2022-02-28 22:20:00",
		"symbol": "BTC/USD",
		"open": 41837.64,
		"high": 41837.64,
		"low": 41805.79,
		"close": 41807.89,
		"Volume BTC": 0.06713449,
		"Volume USD": 2806.7513731261
	},
	{
		"unix": 1646086740,
		"date": "2022-02-28 22:19:00",
		"symbol": "BTC/USD",
		"open": 41807.7,
		"high": 41832.78,
		"low": 41807.7,
		"close": 41827.61,
		"Volume BTC": 0.09934248,
		"Volume USD": 4155.2585098728
	},
	{
		"unix": 1646086680,
		"date": "2022-02-28 22:18:00",
		"symbol": "BTC/USD",
		"open": 41794.62,
		"high": 41809.79,
		"low": 41790.86,
		"close": 41809.79,
		"Volume BTC": 0.15763248,
		"Volume USD": 6590.5808859792
	},
	{
		"unix": 1646086620,
		"date": "2022-02-28 22:17:00",
		"symbol": "BTC/USD",
		"open": 41802.4,
		"high": 41812.05,
		"low": 41766.17,
		"close": 41794.62,
		"Volume BTC": 0.70436651,
		"Volume USD": 29438.7306261762
	},
	{
		"unix": 1646086560,
		"date": "2022-02-28 22:16:00",
		"symbol": "BTC/USD",
		"open": 41747.85,
		"high": 41807.89,
		"low": 41747.85,
		"close": 41807.89,
		"Volume BTC": 0.09056284,
		"Volume USD": 3786.2412528076
	},
	{
		"unix": 1646086500,
		"date": "2022-02-28 22:15:00",
		"symbol": "BTC/USD",
		"open": 41766.13,
		"high": 41768.59,
		"low": 41731.76,
		"close": 41731.76,
		"Volume BTC": 1.07130387,
		"Volume USD": 44707.3959899112
	},
	{
		"unix": 1646086440,
		"date": "2022-02-28 22:14:00",
		"symbol": "BTC/USD",
		"open": 41764.61,
		"high": 41779.59,
		"low": 41737.4,
		"close": 41748.53,
		"Volume BTC": 0.07527795,
		"Volume USD": 3142.7437539135
	},
	{
		"unix": 1646086380,
		"date": "2022-02-28 22:13:00",
		"symbol": "BTC/USD",
		"open": 41748.65,
		"high": 41792.91,
		"low": 41748.65,
		"close": 41783.96,
		"Volume BTC": 0.62401512,
		"Volume USD": 26073.822813475203
	},
	{
		"unix": 1646086320,
		"date": "2022-02-28 22:12:00",
		"symbol": "BTC/USD",
		"open": 41804.3,
		"high": 41804.3,
		"low": 41777.23,
		"close": 41804.3,
		"Volume BTC": 0.15480847,
		"Volume USD": 6471.659722421001
	},
	{
		"unix": 1646086260,
		"date": "2022-02-28 22:11:00",
		"symbol": "BTC/USD",
		"open": 41790.53,
		"high": 41825.3,
		"low": 41777.25,
		"close": 41809.34,
		"Volume BTC": 2.0014312,
		"Volume USD": 83678.51752740798
	},
	{
		"unix": 1646086200,
		"date": "2022-02-28 22:10:00",
		"symbol": "BTC/USD",
		"open": 41811.78,
		"high": 41822.37,
		"low": 41811.78,
		"close": 41822.37,
		"Volume BTC": 0.01524278,
		"Volume USD": 637.4891849886
	},
	{
		"unix": 1646086140,
		"date": "2022-02-28 22:09:00",
		"symbol": "BTC/USD",
		"open": 41841.35,
		"high": 41897.52,
		"low": 41835.71,
		"close": 41897.52,
		"Volume BTC": 0.407542,
		"Volume USD": 17074.999095839998
	},
	{
		"unix": 1646086080,
		"date": "2022-02-28 22:08:00",
		"symbol": "BTC/USD",
		"open": 41857.54,
		"high": 41894.97,
		"low": 41836.35,
		"close": 41836.35,
		"Volume BTC": 0.1014422,
		"Volume USD": 4243.97138397
	},
	{
		"unix": 1646086020,
		"date": "2022-02-28 22:07:00",
		"symbol": "BTC/USD",
		"open": 41722.73,
		"high": 41916,
		"low": 41722.73,
		"close": 41860.9,
		"Volume BTC": 4.1618283,
		"Volume USD": 174217.87828347
	},
	{
		"unix": 1646085960,
		"date": "2022-02-28 22:06:00",
		"symbol": "BTC/USD",
		"open": 41718.53,
		"high": 41721.26,
		"low": 41718.53,
		"close": 41720.38,
		"Volume BTC": 0.00383938,
		"Volume USD": 160.1803925644
	},
	{
		"unix": 1646085900,
		"date": "2022-02-28 22:05:00",
		"symbol": "BTC/USD",
		"open": 41727.4,
		"high": 41727.4,
		"low": 41714.78,
		"close": 41718.55,
		"Volume BTC": 0.02277,
		"Volume USD": 949.9313835
	},
	{
		"unix": 1646085840,
		"date": "2022-02-28 22:04:00",
		"symbol": "BTC/USD",
		"open": 41688.44,
		"high": 41729.99,
		"low": 41688.44,
		"close": 41729.99,
		"Volume BTC": 1.72227799,
		"Volume USD": 71870.64329992009
	},
	{
		"unix": 1646085780,
		"date": "2022-02-28 22:03:00",
		"symbol": "BTC/USD",
		"open": 41657.74,
		"high": 41706.66,
		"low": 41657.74,
		"close": 41688.5,
		"Volume BTC": 0.9823142,
		"Volume USD": 40951.2055267
	},
	{
		"unix": 1646085720,
		"date": "2022-02-28 22:02:00",
		"symbol": "BTC/USD",
		"open": 41691.61,
		"high": 41691.61,
		"low": 41650.29,
		"close": 41650.29,
		"Volume BTC": 0.16645118,
		"Volume USD": 6932.7399178422
	},
	{
		"unix": 1646085660,
		"date": "2022-02-28 22:01:00",
		"symbol": "BTC/USD",
		"open": 41679.73,
		"high": 41691.6,
		"low": 41677.18,
		"close": 41691.6,
		"Volume BTC": 2.66745921,
		"Volume USD": 111210.642399636
	},
	{
		"unix": 1646085600,
		"date": "2022-02-28 22:00:00",
		"symbol": "BTC/USD",
		"open": 41657.23,
		"high": 41711.1,
		"low": 41657.23,
		"close": 41689.86,
		"Volume BTC": 6.1362498,
		"Volume USD": 255819.395087028
	},
	{
		"unix": 1646085540,
		"date": "2022-02-28 21:59:00",
		"symbol": "BTC/USD",
		"open": 41658.57,
		"high": 41673.74,
		"low": 41642.69,
		"close": 41659.53,
		"Volume BTC": 6.17191217,
		"Volume USD": 257118.9602034801
	},
	{
		"unix": 1646085480,
		"date": "2022-02-28 21:58:00",
		"symbol": "BTC/USD",
		"open": 41649.38,
		"high": 41651.34,
		"low": 41634.64,
		"close": 41645.92,
		"Volume BTC": 7.82155495,
		"Volume USD": 325735.851723304
	},
	{
		"unix": 1646085420,
		"date": "2022-02-28 21:57:00",
		"symbol": "BTC/USD",
		"open": 41633.49,
		"high": 41636,
		"low": 41617.48,
		"close": 41636,
		"Volume BTC": 3.98651484,
		"Volume USD": 165982.53187824
	},
	{
		"unix": 1646085360,
		"date": "2022-02-28 21:56:00",
		"symbol": "BTC/USD",
		"open": 41640.4,
		"high": 41640.4,
		"low": 41597.71,
		"close": 41620.87,
		"Volume BTC": 5.46630038,
		"Volume USD": 227512.1774969306
	},
	{
		"unix": 1646085300,
		"date": "2022-02-28 21:55:00",
		"symbol": "BTC/USD",
		"open": 41599.91,
		"high": 41636.41,
		"low": 41593.2,
		"close": 41636.41,
		"Volume BTC": 3.24856798,
		"Volume USD": 135258.7083281518
	},
	{
		"unix": 1646085240,
		"date": "2022-02-28 21:54:00",
		"symbol": "BTC/USD",
		"open": 41585.64,
		"high": 41602.78,
		"low": 41578.74,
		"close": 41602.78,
		"Volume BTC": 2.35343644,
		"Volume USD": 97909.4984573032
	},
	{
		"unix": 1646085180,
		"date": "2022-02-28 21:53:00",
		"symbol": "BTC/USD",
		"open": 41633.16,
		"high": 41633.16,
		"low": 41581.3,
		"close": 41604.23,
		"Volume BTC": 0.06565477,
		"Volume USD": 2731.5161516771004
	},
	{
		"unix": 1646085120,
		"date": "2022-02-28 21:52:00",
		"symbol": "BTC/USD",
		"open": 41656.3,
		"high": 41656.3,
		"low": 41604.42,
		"close": 41604.42,
		"Volume BTC": 0.06726,
		"Volume USD": 2798.3132892
	},
	{
		"unix": 1646085060,
		"date": "2022-02-28 21:51:00",
		"symbol": "BTC/USD",
		"open": 41653.18,
		"high": 41656.85,
		"low": 41651.1,
		"close": 41654.68,
		"Volume BTC": 0.00605027,
		"Volume USD": 252.0220607636
	},
	{
		"unix": 1646085000,
		"date": "2022-02-28 21:50:00",
		"symbol": "BTC/USD",
		"open": 41639.07,
		"high": 41658.03,
		"low": 41625.65,
		"close": 41625.65,
		"Volume BTC": 0.02895333,
		"Volume USD": 1205.2011809145001
	},
	{
		"unix": 1646084940,
		"date": "2022-02-28 21:49:00",
		"symbol": "BTC/USD",
		"open": 41676.15,
		"high": 41676.15,
		"low": 41674.81,
		"close": 41674.81,
		"Volume BTC": 0.00346989,
		"Volume USD": 144.6070064709
	},
	{
		"unix": 1646084880,
		"date": "2022-02-28 21:48:00",
		"symbol": "BTC/USD",
		"open": 41677.13,
		"high": 41688.78,
		"low": 41645.97,
		"close": 41645.97,
		"Volume BTC": 0.08079824,
		"Volume USD": 3364.9210790928
	},
	{
		"unix": 1646084820,
		"date": "2022-02-28 21:47:00",
		"symbol": "BTC/USD",
		"open": 41656.55,
		"high": 41685.77,
		"low": 41656.55,
		"close": 41685.77,
		"Volume BTC": 1.341155,
		"Volume USD": 55907.07886435
	},
	{
		"unix": 1646084760,
		"date": "2022-02-28 21:46:00",
		"symbol": "BTC/USD",
		"open": 41592.31,
		"high": 41665.71,
		"low": 41592.31,
		"close": 41665.71,
		"Volume BTC": 0.72690361,
		"Volume USD": 30286.9550122131
	},
	{
		"unix": 1646084700,
		"date": "2022-02-28 21:45:00",
		"symbol": "BTC/USD",
		"open": 41572.91,
		"high": 41583.17,
		"low": 41565.8,
		"close": 41583.17,
		"Volume BTC": 0.02046943,
		"Volume USD": 851.1837874931
	},
	{
		"unix": 1646084640,
		"date": "2022-02-28 21:44:00",
		"symbol": "BTC/USD",
		"open": 41565.8,
		"high": 41608.62,
		"low": 41565.8,
		"close": 41586.56,
		"Volume BTC": 0.77756333,
		"Volume USD": 32336.1840768448
	},
	{
		"unix": 1646084580,
		"date": "2022-02-28 21:43:00",
		"symbol": "BTC/USD",
		"open": 41556.72,
		"high": 41590.56,
		"low": 41556.72,
		"close": 41590.47,
		"Volume BTC": 0.03560221,
		"Volume USD": 1480.7126469387001
	},
	{
		"unix": 1646084520,
		"date": "2022-02-28 21:42:00",
		"symbol": "BTC/USD",
		"open": 41565.97,
		"high": 41565.97,
		"low": 41542.6,
		"close": 41546.86,
		"Volume BTC": 0.00915107,
		"Volume USD": 380.1982241402
	},
	{
		"unix": 1646084460,
		"date": "2022-02-28 21:41:00",
		"symbol": "BTC/USD",
		"open": 41580.47,
		"high": 41583.21,
		"low": 41546.39,
		"close": 41546.39,
		"Volume BTC": 0.04698629,
		"Volume USD": 1952.1107289930999
	},
	{
		"unix": 1646084400,
		"date": "2022-02-28 21:40:00",
		"symbol": "BTC/USD",
		"open": 41615.83,
		"high": 41615.83,
		"low": 41575.66,
		"close": 41596.38,
		"Volume BTC": 1.86124452,
		"Volume USD": 77421.0343268376
	},
	{
		"unix": 1646084340,
		"date": "2022-02-28 21:39:00",
		"symbol": "BTC/USD",
		"open": 41602.82,
		"high": 41626.88,
		"low": 41602.14,
		"close": 41618.05,
		"Volume BTC": 0.15627,
		"Volume USD": 6503.6526735
	},
	{
		"unix": 1646084280,
		"date": "2022-02-28 21:38:00",
		"symbol": "BTC/USD",
		"open": 41573.2,
		"high": 41614.06,
		"low": 41573.19,
		"close": 41602.82,
		"Volume BTC": 0.2320968,
		"Volume USD": 9655.881392976
	},
	{
		"unix": 1646084220,
		"date": "2022-02-28 21:37:00",
		"symbol": "BTC/USD",
		"open": 41578.93,
		"high": 41606.8,
		"low": 41570.64,
		"close": 41570.64,
		"Volume BTC": 1.53574722,
		"Volume USD": 63841.9948136208
	},
	{
		"unix": 1646084160,
		"date": "2022-02-28 21:36:00",
		"symbol": "BTC/USD",
		"open": 41622.08,
		"high": 41624.07,
		"low": 41607.07,
		"close": 41607.07,
		"Volume BTC": 0.02520563,
		"Volume USD": 1048.7324118041
	},
	{
		"unix": 1646084100,
		"date": "2022-02-28 21:35:00",
		"symbol": "BTC/USD",
		"open": 41635,
		"high": 41647,
		"low": 41611.43,
		"close": 41636.49,
		"Volume BTC": 0.10955922,
		"Volume USD": 4561.6613679378
	},
	{
		"unix": 1646084040,
		"date": "2022-02-28 21:34:00",
		"symbol": "BTC/USD",
		"open": 41614.68,
		"high": 41644.03,
		"low": 41614.68,
		"close": 41641.23,
		"Volume BTC": 0.0513874,
		"Volume USD": 2139.834542502
	},
	{
		"unix": 1646083980,
		"date": "2022-02-28 21:33:00",
		"symbol": "BTC/USD",
		"open": 41627.16,
		"high": 41640.85,
		"low": 41611.24,
		"close": 41613.82,
		"Volume BTC": 0.56814932,
		"Volume USD": 23642.8635356024
	},
	{
		"unix": 1646083920,
		"date": "2022-02-28 21:32:00",
		"symbol": "BTC/USD",
		"open": 41577.8,
		"high": 41612.07,
		"low": 41562.42,
		"close": 41562.42,
		"Volume BTC": 0.08070952,
		"Volume USD": 3354.4829682384
	},
	{
		"unix": 1646083860,
		"date": "2022-02-28 21:31:00",
		"symbol": "BTC/USD",
		"open": 41623.86,
		"high": 41637.76,
		"low": 41566.1,
		"close": 41584.33,
		"Volume BTC": 2.72310076,
		"Volume USD": 113238.3206270908
	},
	{
		"unix": 1646083800,
		"date": "2022-02-28 21:30:00",
		"symbol": "BTC/USD",
		"open": 41640.02,
		"high": 41640.02,
		"low": 41581.08,
		"close": 41609.77,
		"Volume BTC": 2.12928291,
		"Volume USD": 88598.9721500307
	},
	{
		"unix": 1646083740,
		"date": "2022-02-28 21:29:00",
		"symbol": "BTC/USD",
		"open": 41680,
		"high": 41680,
		"low": 41650.03,
		"close": 41650.03,
		"Volume BTC": 1.04596,
		"Volume USD": 43564.265378799995
	},
	{
		"unix": 1646083680,
		"date": "2022-02-28 21:28:00",
		"symbol": "BTC/USD",
		"open": 41673.29,
		"high": 41690,
		"low": 41645.99,
		"close": 41680,
		"Volume BTC": 0.13609972,
		"Volume USD": 5672.6363296
	},
	{
		"unix": 1646083620,
		"date": "2022-02-28 21:27:00",
		"symbol": "BTC/USD",
		"open": 41673.36,
		"high": 41673.36,
		"low": 41650,
		"close": 41673.29,
		"Volume BTC": 3.00559,
		"Volume USD": 125252.82369110001
	},
	{
		"unix": 1646083560,
		"date": "2022-02-28 21:26:00",
		"symbol": "BTC/USD",
		"open": 41680.63,
		"high": 41694.41,
		"low": 41673,
		"close": 41673,
		"Volume BTC": 0.19412,
		"Volume USD": 8089.56276
	},
	{
		"unix": 1646083500,
		"date": "2022-02-28 21:25:00",
		"symbol": "BTC/USD",
		"open": 41700.61,
		"high": 41703.7,
		"low": 41653.56,
		"close": 41680.63,
		"Volume BTC": 2.25121881,
		"Volume USD": 93832.2182686503
	},
	{
		"unix": 1646083440,
		"date": "2022-02-28 21:24:00",
		"symbol": "BTC/USD",
		"open": 41684.86,
		"high": 41700.56,
		"low": 41682.68,
		"close": 41700.56,
		"Volume BTC": 0.51491,
		"Volume USD": 21472.0353496
	},
	{
		"unix": 1646083380,
		"date": "2022-02-28 21:23:00",
		"symbol": "BTC/USD",
		"open": 41724.44,
		"high": 41724.57,
		"low": 41718.98,
		"close": 41718.98,
		"Volume BTC": 0.517,
		"Volume USD": 21568.71266
	},
	{
		"unix": 1646083320,
		"date": "2022-02-28 21:22:00",
		"symbol": "BTC/USD",
		"open": 41675.99,
		"high": 41724.57,
		"low": 41661.57,
		"close": 41724.19,
		"Volume BTC": 0.29631111,
		"Volume USD": 12363.3410527509
	},
	{
		"unix": 1646083260,
		"date": "2022-02-28 21:21:00",
		"symbol": "BTC/USD",
		"open": 41671.07,
		"high": 41671.48,
		"low": 41631.35,
		"close": 41671.48,
		"Volume BTC": 0.99980386,
		"Volume USD": 41663.3065559128
	},
	{
		"unix": 1646083200,
		"date": "2022-02-28 21:20:00",
		"symbol": "BTC/USD",
		"open": 41697.1,
		"high": 41697.1,
		"low": 41630.82,
		"close": 41649.62,
		"Volume BTC": 0.42622639,
		"Volume USD": 17752.1671774718
	},
	{
		"unix": 1646083140,
		"date": "2022-02-28 21:19:00",
		"symbol": "BTC/USD",
		"open": 41684.95,
		"high": 41696.85,
		"low": 41666.07,
		"close": 41686.57,
		"Volume BTC": 0.21622938,
		"Volume USD": 9013.8611854266
	},
	{
		"unix": 1646083080,
		"date": "2022-02-28 21:18:00",
		"symbol": "BTC/USD",
		"open": 41740.79,
		"high": 41740.79,
		"low": 41651.85,
		"close": 41673.71,
		"Volume BTC": 1.00891949,
		"Volume USD": 42045.4182396079
	},
	{
		"unix": 1646083020,
		"date": "2022-02-28 21:17:00",
		"symbol": "BTC/USD",
		"open": 41758.87,
		"high": 41763.62,
		"low": 41756.82,
		"close": 41761.54,
		"Volume BTC": 1.4239851,
		"Volume USD": 59467.810713053994
	},
	{
		"unix": 1646082960,
		"date": "2022-02-28 21:16:00",
		"symbol": "BTC/USD",
		"open": 41749.89,
		"high": 41761.55,
		"low": 41722.88,
		"close": 41757.27,
		"Volume BTC": 0.57079,
		"Volume USD": 23834.632143299998
	},
	{
		"unix": 1646082900,
		"date": "2022-02-28 21:15:00",
		"symbol": "BTC/USD",
		"open": 41740.29,
		"high": 41789.46,
		"low": 41739.5,
		"close": 41746.53,
		"Volume BTC": 0.04307537,
		"Volume USD": 1798.2472259661001
	},
	{
		"unix": 1646082840,
		"date": "2022-02-28 21:14:00",
		"symbol": "BTC/USD",
		"open": 41741.9,
		"high": 41752.51,
		"low": 41736.69,
		"close": 41748.7,
		"Volume BTC": 0.3738925,
		"Volume USD": 15609.525814749999
	},
	{
		"unix": 1646082780,
		"date": "2022-02-28 21:13:00",
		"symbol": "BTC/USD",
		"open": 41687.39,
		"high": 41768.96,
		"low": 41687.39,
		"close": 41760.95,
		"Volume BTC": 0.10037959,
		"Volume USD": 4191.9470390105
	},
	{
		"unix": 1646082720,
		"date": "2022-02-28 21:12:00",
		"symbol": "BTC/USD",
		"open": 41668.49,
		"high": 41712.84,
		"low": 41668.49,
		"close": 41687.4,
		"Volume BTC": 0.33920384,
		"Volume USD": 14140.526159616
	},
	{
		"unix": 1646082660,
		"date": "2022-02-28 21:11:00",
		"symbol": "BTC/USD",
		"open": 41701.23,
		"high": 41703.37,
		"low": 41675.62,
		"close": 41675.62,
		"Volume BTC": 0.37291311,
		"Volume USD": 15541.385065378201
	},
	{
		"unix": 1646082600,
		"date": "2022-02-28 21:10:00",
		"symbol": "BTC/USD",
		"open": 41684.54,
		"high": 41703.37,
		"low": 41684.54,
		"close": 41703.37,
		"Volume BTC": 0.78625435,
		"Volume USD": 32789.456072159504
	},
	{
		"unix": 1646082540,
		"date": "2022-02-28 21:09:00",
		"symbol": "BTC/USD",
		"open": 41687.26,
		"high": 41699.68,
		"low": 41625.68,
		"close": 41643.41,
		"Volume BTC": 0.26332429,
		"Volume USD": 10965.721371428901
	},
	{
		"unix": 1646082480,
		"date": "2022-02-28 21:08:00",
		"symbol": "BTC/USD",
		"open": 41676.98,
		"high": 41687.39,
		"low": 41676.98,
		"close": 41687.27,
		"Volume BTC": 0.16001885,
		"Volume USD": 6670.749005039499
	},
	{
		"unix": 1646082420,
		"date": "2022-02-28 21:07:00",
		"symbol": "BTC/USD",
		"open": 41710,
		"high": 41740.49,
		"low": 41710,
		"close": 41725.91,
		"Volume BTC": 5.17118482,
		"Volume USD": 215772.39239268622
	},
	{
		"unix": 1646082360,
		"date": "2022-02-28 21:06:00",
		"symbol": "BTC/USD",
		"open": 41695.58,
		"high": 41715.74,
		"low": 41683.9,
		"close": 41710,
		"Volume BTC": 1.58668241,
		"Volume USD": 66180.5233211
	},
	{
		"unix": 1646082300,
		"date": "2022-02-28 21:05:00",
		"symbol": "BTC/USD",
		"open": 41765.83,
		"high": 41765.83,
		"low": 41715.8,
		"close": 41729.59,
		"Volume BTC": 0.12525256,
		"Volume USD": 5226.7379752504
	},
	{
		"unix": 1646082240,
		"date": "2022-02-28 21:04:00",
		"symbol": "BTC/USD",
		"open": 41725.53,
		"high": 41746.01,
		"low": 41725.53,
		"close": 41733.56,
		"Volume BTC": 0.2085136,
		"Volume USD": 8702.014836416
	},
	{
		"unix": 1646082180,
		"date": "2022-02-28 21:03:00",
		"symbol": "BTC/USD",
		"open": 41802.32,
		"high": 41802.32,
		"low": 41704.84,
		"close": 41744.44,
		"Volume BTC": 2.93637203,
		"Volume USD": 122577.2060240132
	},
	{
		"unix": 1646082120,
		"date": "2022-02-28 21:02:00",
		"symbol": "BTC/USD",
		"open": 41848.17,
		"high": 41848.17,
		"low": 41790,
		"close": 41820.39,
		"Volume BTC": 0.4930559,
		"Volume USD": 20619.790029801
	},
	{
		"unix": 1646082060,
		"date": "2022-02-28 21:01:00",
		"symbol": "BTC/USD",
		"open": 41834.9,
		"high": 41856.35,
		"low": 41820.39,
		"close": 41847.92,
		"Volume BTC": 0.67413229,
		"Volume USD": 28211.034141336797
	},
	{
		"unix": 1646082000,
		"date": "2022-02-28 21:00:00",
		"symbol": "BTC/USD",
		"open": 41917.09,
		"high": 41917.09,
		"low": 41843.95,
		"close": 41863.05,
		"Volume BTC": 1.7792031,
		"Volume USD": 74482.868335455
	},
	{
		"unix": 1646081940,
		"date": "2022-02-28 20:59:00",
		"symbol": "BTC/USD",
		"open": 41850.82,
		"high": 41932.8,
		"low": 41850.82,
		"close": 41914.97,
		"Volume BTC": 0.74901333,
		"Volume USD": 31394.8712565501
	},
	{
		"unix": 1646081880,
		"date": "2022-02-28 20:58:00",
		"symbol": "BTC/USD",
		"open": 41806.62,
		"high": 41910.75,
		"low": 41806.62,
		"close": 41899.46,
		"Volume BTC": 1.85101117,
		"Volume USD": 77556.3684769682
	},
	{
		"unix": 1646081820,
		"date": "2022-02-28 20:57:00",
		"symbol": "BTC/USD",
		"open": 41817.16,
		"high": 41866.7,
		"low": 41809.78,
		"close": 41815.21,
		"Volume BTC": 0.87752,
		"Volume USD": 36693.6830792
	},
	{
		"unix": 1646081760,
		"date": "2022-02-28 20:56:00",
		"symbol": "BTC/USD",
		"open": 41799.37,
		"high": 41865.18,
		"low": 41799.37,
		"close": 41817.16,
		"Volume BTC": 0.82463458,
		"Volume USD": 34483.876173392804
	},
	{
		"unix": 1646081700,
		"date": "2022-02-28 20:55:00",
		"symbol": "BTC/USD",
		"open": 41926.16,
		"high": 41942.81,
		"low": 41809.43,
		"close": 41809.58,
		"Volume BTC": 6.6905901,
		"Volume USD": 279730.762033158
	},
	{
		"unix": 1646081640,
		"date": "2022-02-28 20:54:00",
		"symbol": "BTC/USD",
		"open": 41771.11,
		"high": 41900.3,
		"low": 41738.98,
		"close": 41900.3,
		"Volume BTC": 5.28640841,
		"Volume USD": 221502.09830152302
	},
	{
		"unix": 1646081580,
		"date": "2022-02-28 20:53:00",
		"symbol": "BTC/USD",
		"open": 41793.56,
		"high": 41832.34,
		"low": 41777.48,
		"close": 41832.34,
		"Volume BTC": 0.35870344,
		"Volume USD": 15005.404261249598
	},
	{
		"unix": 1646081520,
		"date": "2022-02-28 20:52:00",
		"symbol": "BTC/USD",
		"open": 41851.12,
		"high": 41871.74,
		"low": 41793.55,
		"close": 41793.55,
		"Volume BTC": 0.79983291,
		"Volume USD": 33427.8567157305
	},
	{
		"unix": 1646081460,
		"date": "2022-02-28 20:51:00",
		"symbol": "BTC/USD",
		"open": 41931.63,
		"high": 41931.64,
		"low": 41835.83,
		"close": 41875.09,
		"Volume BTC": 1.81101514,
		"Volume USD": 75836.42197886259
	},
	{
		"unix": 1646081400,
		"date": "2022-02-28 20:50:00",
		"symbol": "BTC/USD",
		"open": 41900.44,
		"high": 41971,
		"low": 41890.95,
		"close": 41890.95,
		"Volume BTC": 3.89370735,
		"Volume USD": 163111.0999134825
	},
	{
		"unix": 1646081340,
		"date": "2022-02-28 20:49:00",
		"symbol": "BTC/USD",
		"open": 41821,
		"high": 41926.26,
		"low": 41819.67,
		"close": 41910.51,
		"Volume BTC": 3.26306825,
		"Volume USD": 136756.8545223075
	},
	{
		"unix": 1646081280,
		"date": "2022-02-28 20:48:00",
		"symbol": "BTC/USD",
		"open": 41828.01,
		"high": 41853.02,
		"low": 41785.15,
		"close": 41853.02,
		"Volume BTC": 0.24063618,
		"Volume USD": 10071.3508542636
	},
	{
		"unix": 1646081220,
		"date": "2022-02-28 20:47:00",
		"symbol": "BTC/USD",
		"open": 41787.15,
		"high": 41838.48,
		"low": 41787.15,
		"close": 41823.67,
		"Volume BTC": 6.00372793,
		"Volume USD": 251097.9357141031
	},
	{
		"unix": 1646081160,
		"date": "2022-02-28 20:46:00",
		"symbol": "BTC/USD",
		"open": 41799.73,
		"high": 41882.54,
		"low": 41791.12,
		"close": 41826.96,
		"Volume BTC": 1.34958222,
		"Volume USD": 56448.9215326512
	},
	{
		"unix": 1646081100,
		"date": "2022-02-28 20:45:00",
		"symbol": "BTC/USD",
		"open": 41805.07,
		"high": 41832.34,
		"low": 41724.55,
		"close": 41766.28,
		"Volume BTC": 4.45988,
		"Volume USD": 186272.5968464
	},
	{
		"unix": 1646081040,
		"date": "2022-02-28 20:44:00",
		"symbol": "BTC/USD",
		"open": 41700.91,
		"high": 41789.3,
		"low": 41682.11,
		"close": 41789.3,
		"Volume BTC": 0.26176062,
		"Volume USD": 10938.793077366001
	},
	{
		"unix": 1646080980,
		"date": "2022-02-28 20:43:00",
		"symbol": "BTC/USD",
		"open": 41671.81,
		"high": 41682.11,
		"low": 41641.08,
		"close": 41682.11,
		"Volume BTC": 0.0823319,
		"Volume USD": 3431.767312309
	},
	{
		"unix": 1646080920,
		"date": "2022-02-28 20:42:00",
		"symbol": "BTC/USD",
		"open": 41652.61,
		"high": 41684.55,
		"low": 41630.25,
		"close": 41652.19,
		"Volume BTC": 0.37336746,
		"Volume USD": 15551.5723837374
	},
	{
		"unix": 1646080860,
		"date": "2022-02-28 20:41:00",
		"symbol": "BTC/USD",
		"open": 41608.37,
		"high": 41680.12,
		"low": 41601.14,
		"close": 41680.12,
		"Volume BTC": 1.21277,
		"Volume USD": 50548.3991324
	},
	{
		"unix": 1646080800,
		"date": "2022-02-28 20:40:00",
		"symbol": "BTC/USD",
		"open": 41587.7,
		"high": 41624.82,
		"low": 41579.61,
		"close": 41617.77,
		"Volume BTC": 7.59211564,
		"Volume USD": 315966.9225189228
	},
	{
		"unix": 1646080740,
		"date": "2022-02-28 20:39:00",
		"symbol": "BTC/USD",
		"open": 41500,
		"high": 41586.41,
		"low": 41500,
		"close": 41577.74,
		"Volume BTC": 0.05988327,
		"Volume USD": 2489.8110304098
	},
	{
		"unix": 1646080680,
		"date": "2022-02-28 20:38:00",
		"symbol": "BTC/USD",
		"open": 41546.31,
		"high": 41546.31,
		"low": 41504.63,
		"close": 41504.63,
		"Volume BTC": 0.04063001,
		"Volume USD": 1686.3335319463
	},
	{
		"unix": 1646080620,
		"date": "2022-02-28 20:37:00",
		"symbol": "BTC/USD",
		"open": 41505.45,
		"high": 41552.64,
		"low": 41505.45,
		"close": 41546.31,
		"Volume BTC": 0.03290721,
		"Volume USD": 1367.1731478950999
	},
	{
		"unix": 1646080560,
		"date": "2022-02-28 20:36:00",
		"symbol": "BTC/USD",
		"open": 41533.75,
		"high": 41533.75,
		"low": 41473.68,
		"close": 41528.91,
		"Volume BTC": 4.38889421,
		"Volume USD": 182265.99264661112
	},
	{
		"unix": 1646080500,
		"date": "2022-02-28 20:35:00",
		"symbol": "BTC/USD",
		"open": 41565.22,
		"high": 41573.74,
		"low": 41500.51,
		"close": 41550.46,
		"Volume BTC": 0.27760658,
		"Volume USD": 11534.6810980268
	},
	{
		"unix": 1646080440,
		"date": "2022-02-28 20:34:00",
		"symbol": "BTC/USD",
		"open": 41572.5,
		"high": 41589.34,
		"low": 41537.95,
		"close": 41581.89,
		"Volume BTC": 3.04889655,
		"Volume USD": 126778.88096347949
	},
	{
		"unix": 1646080380,
		"date": "2022-02-28 20:33:00",
		"symbol": "BTC/USD",
		"open": 41601.03,
		"high": 41601.03,
		"low": 41554.22,
		"close": 41554.22,
		"Volume BTC": 4.66502555,
		"Volume USD": 193851.49801032103
	},
	{
		"unix": 1646080320,
		"date": "2022-02-28 20:32:00",
		"symbol": "BTC/USD",
		"open": 41649.8,
		"high": 41675.01,
		"low": 41621.94,
		"close": 41621.94,
		"Volume BTC": 1.83497916,
		"Volume USD": 76375.39249877041
	},
	{
		"unix": 1646080260,
		"date": "2022-02-28 20:31:00",
		"symbol": "BTC/USD",
		"open": 41597.82,
		"high": 41626.82,
		"low": 41597.82,
		"close": 41626.82,
		"Volume BTC": 0.19394,
		"Volume USD": 8073.1054708
	},
	{
		"unix": 1646080200,
		"date": "2022-02-28 20:30:00",
		"symbol": "BTC/USD",
		"open": 41691.39,
		"high": 41733.32,
		"low": 41623.57,
		"close": 41623.57,
		"Volume BTC": 7.48099978,
		"Volume USD": 311385.9180128146
	},
	{
		"unix": 1646080140,
		"date": "2022-02-28 20:29:00",
		"symbol": "BTC/USD",
		"open": 41700,
		"high": 41701.63,
		"low": 41643.63,
		"close": 41680.93,
		"Volume BTC": 10.2597191,
		"Volume USD": 427634.633626763
	},
	{
		"unix": 1646080080,
		"date": "2022-02-28 20:28:00",
		"symbol": "BTC/USD",
		"open": 41720.22,
		"high": 41765.56,
		"low": 41699.93,
		"close": 41706.47,
		"Volume BTC": 3.95090742,
		"Volume USD": 164778.40178500742
	},
	{
		"unix": 1646080020,
		"date": "2022-02-28 20:27:00",
		"symbol": "BTC/USD",
		"open": 41641.25,
		"high": 41721.21,
		"low": 41639.18,
		"close": 41709.18,
		"Volume BTC": 5.33902793,
		"Volume USD": 222686.47695739742
	},
	{
		"unix": 1646079960,
		"date": "2022-02-28 20:26:00",
		"symbol": "BTC/USD",
		"open": 41649.59,
		"high": 41674.16,
		"low": 41639.18,
		"close": 41674.16,
		"Volume BTC": 7.6783331,
		"Volume USD": 319988.082142696
	},
	{
		"unix": 1646079900,
		"date": "2022-02-28 20:25:00",
		"symbol": "BTC/USD",
		"open": 41630.95,
		"high": 41692.81,
		"low": 41630.95,
		"close": 41639.18,
		"Volume BTC": 8.05862469,
		"Volume USD": 335554.5240193542
	},
	{
		"unix": 1646079840,
		"date": "2022-02-28 20:24:00",
		"symbol": "BTC/USD",
		"open": 41687.41,
		"high": 41687.41,
		"low": 41638.78,
		"close": 41672.33,
		"Volume BTC": 5.12834192,
		"Volume USD": 213709.95684307363
	},
	{
		"unix": 1646079780,
		"date": "2022-02-28 20:23:00",
		"symbol": "BTC/USD",
		"open": 41735.99,
		"high": 41735.99,
		"low": 41659.91,
		"close": 41700.12,
		"Volume BTC": 7.98207829,
		"Volume USD": 332853.6225423948
	},
	{
		"unix": 1646079720,
		"date": "2022-02-28 20:22:00",
		"symbol": "BTC/USD",
		"open": 41730.64,
		"high": 41813.76,
		"low": 41730.64,
		"close": 41793.2,
		"Volume BTC": 16.57038898,
		"Volume USD": 692529.5807189359
	},
	{
		"unix": 1646079660,
		"date": "2022-02-28 20:21:00",
		"symbol": "BTC/USD",
		"open": 41575.86,
		"high": 41732.13,
		"low": 41571.74,
		"close": 41730.04,
		"Volume BTC": 15.13060571,
		"Volume USD": 631400.7815025283
	},
	{
		"unix": 1646079600,
		"date": "2022-02-28 20:20:00",
		"symbol": "BTC/USD",
		"open": 41515.99,
		"high": 41592.45,
		"low": 41515.99,
		"close": 41592.45,
		"Volume BTC": 0.00775445,
		"Volume USD": 322.5265739025
	},
	{
		"unix": 1646079540,
		"date": "2022-02-28 20:19:00",
		"symbol": "BTC/USD",
		"open": 41501.1,
		"high": 41518.09,
		"low": 41480.43,
		"close": 41492.09,
		"Volume BTC": 3.07376,
		"Volume USD": 127536.7265584
	},
	{
		"unix": 1646079480,
		"date": "2022-02-28 20:18:00",
		"symbol": "BTC/USD",
		"open": 41404.12,
		"high": 41482.63,
		"low": 41382.01,
		"close": 41466.93,
		"Volume BTC": 3.77749813,
		"Volume USD": 156641.2505318409
	},
	{
		"unix": 1646079420,
		"date": "2022-02-28 20:17:00",
		"symbol": "BTC/USD",
		"open": 41418.5,
		"high": 41418.5,
		"low": 41383.93,
		"close": 41404.79,
		"Volume BTC": 0.70226734,
		"Volume USD": 29077.231736558602
	},
	{
		"unix": 1646079360,
		"date": "2022-02-28 20:16:00",
		"symbol": "BTC/USD",
		"open": 41402.91,
		"high": 41402.91,
		"low": 41361.86,
		"close": 41361.86,
		"Volume BTC": 4.60324837,
		"Volume USD": 190398.91462516823
	},
	{
		"unix": 1646079300,
		"date": "2022-02-28 20:15:00",
		"symbol": "BTC/USD",
		"open": 41435.98,
		"high": 41484.91,
		"low": 41383.25,
		"close": 41395.07,
		"Volume BTC": 3.70771837,
		"Volume USD": 153481.26146643588
	},
	{
		"unix": 1646079240,
		"date": "2022-02-28 20:14:00",
		"symbol": "BTC/USD",
		"open": 41458.27,
		"high": 41458.27,
		"low": 41412.15,
		"close": 41412.15,
		"Volume BTC": 4.03158062,
		"Volume USD": 166956.421372533
	},
	{
		"unix": 1646079180,
		"date": "2022-02-28 20:13:00",
		"symbol": "BTC/USD",
		"open": 41400,
		"high": 41441,
		"low": 41384.39,
		"close": 41434.68,
		"Volume BTC": 1.33679967,
		"Volume USD": 55389.8665505556
	},
	{
		"unix": 1646079120,
		"date": "2022-02-28 20:12:00",
		"symbol": "BTC/USD",
		"open": 41422,
		"high": 41422,
		"low": 41346.86,
		"close": 41362.12,
		"Volume BTC": 3.20153295,
		"Volume USD": 132422.190061854
	},
	{
		"unix": 1646079060,
		"date": "2022-02-28 20:11:00",
		"symbol": "BTC/USD",
		"open": 41419.16,
		"high": 41475.42,
		"low": 41419.16,
		"close": 41422,
		"Volume BTC": 1.42684983,
		"Volume USD": 59102.97365826
	},
	{
		"unix": 1646079000,
		"date": "2022-02-28 20:10:00",
		"symbol": "BTC/USD",
		"open": 41469.06,
		"high": 41474.56,
		"low": 41418.47,
		"close": 41438.95,
		"Volume BTC": 4.77996416,
		"Volume USD": 198076.69582803198
	},
	{
		"unix": 1646078940,
		"date": "2022-02-28 20:09:00",
		"symbol": "BTC/USD",
		"open": 41448.87,
		"high": 41489.52,
		"low": 41448.87,
		"close": 41489.52,
		"Volume BTC": 2.86023731,
		"Volume USD": 118669.87307799119
	},
	{
		"unix": 1646078880,
		"date": "2022-02-28 20:08:00",
		"symbol": "BTC/USD",
		"open": 41371.17,
		"high": 41459.99,
		"low": 41371.17,
		"close": 41459.99,
		"Volume BTC": 1.44332336,
		"Volume USD": 59840.1720723664
	},
	{
		"unix": 1646078820,
		"date": "2022-02-28 20:07:00",
		"symbol": "BTC/USD",
		"open": 41333.47,
		"high": 41370.43,
		"low": 41324.16,
		"close": 41357.4,
		"Volume BTC": 9.20900525,
		"Volume USD": 380860.51372635004
	},
	{
		"unix": 1646078760,
		"date": "2022-02-28 20:06:00",
		"symbol": "BTC/USD",
		"open": 41330.17,
		"high": 41338.97,
		"low": 41284.11,
		"close": 41289.69,
		"Volume BTC": 5.51795471,
		"Volume USD": 227834.6394099399
	},
	{
		"unix": 1646078700,
		"date": "2022-02-28 20:05:00",
		"symbol": "BTC/USD",
		"open": 41483.52,
		"high": 41483.52,
		"low": 41369.61,
		"close": 41380.35,
		"Volume BTC": 13.61045927,
		"Volume USD": 563205.5682533445
	},
	{
		"unix": 1646078640,
		"date": "2022-02-28 20:04:00",
		"symbol": "BTC/USD",
		"open": 41576.3,
		"high": 41576.3,
		"low": 41459.38,
		"close": 41501.45,
		"Volume BTC": 7.10526021,
		"Volume USD": 294878.6013423045
	},
	{
		"unix": 1646078580,
		"date": "2022-02-28 20:03:00",
		"symbol": "BTC/USD",
		"open": 41344.77,
		"high": 41610.53,
		"low": 41344.77,
		"close": 41521.13,
		"Volume BTC": 21.07362803,
		"Volume USD": 875000.8490052738
	},
	{
		"unix": 1646078520,
		"date": "2022-02-28 20:02:00",
		"symbol": "BTC/USD",
		"open": 41348.76,
		"high": 41371.96,
		"low": 41337.66,
		"close": 41337.66,
		"Volume BTC": 4.42567999,
		"Volume USD": 182947.2546954234
	},
	{
		"unix": 1646078460,
		"date": "2022-02-28 20:01:00",
		"symbol": "BTC/USD",
		"open": 41377.27,
		"high": 41383.56,
		"low": 41375.83,
		"close": 41375.83,
		"Volume BTC": 0.05440664,
		"Volume USD": 2251.1198875112
	},
	{
		"unix": 1646078400,
		"date": "2022-02-28 20:00:00",
		"symbol": "BTC/USD",
		"open": 41361.99,
		"high": 41386.68,
		"low": 41361.32,
		"close": 41379.15,
		"Volume BTC": 1.09925962,
		"Volume USD": 45486.428704923004
	},
	{
		"unix": 1646078340,
		"date": "2022-02-28 19:59:00",
		"symbol": "BTC/USD",
		"open": 41386.57,
		"high": 41392.39,
		"low": 41328.92,
		"close": 41362.52,
		"Volume BTC": 0.06891067,
		"Volume USD": 2850.3189660883995
	},
	{
		"unix": 1646078280,
		"date": "2022-02-28 19:58:00",
		"symbol": "BTC/USD",
		"open": 41388.16,
		"high": 41392.41,
		"low": 41377.19,
		"close": 41388.77,
		"Volume BTC": 0.02741,
		"Volume USD": 1134.4661856999999
	},
	{
		"unix": 1646078220,
		"date": "2022-02-28 19:57:00",
		"symbol": "BTC/USD",
		"open": 41367.65,
		"high": 41390.33,
		"low": 41356.94,
		"close": 41388.16,
		"Volume BTC": 1.11925634,
		"Volume USD": 46323.9604809344
	},
	{
		"unix": 1646078160,
		"date": "2022-02-28 19:56:00",
		"symbol": "BTC/USD",
		"open": 41327.59,
		"high": 41371.5,
		"low": 41327.59,
		"close": 41371.5,
		"Volume BTC": 0.07223,
		"Volume USD": 2988.263445
	},
	{
		"unix": 1646078100,
		"date": "2022-02-28 19:55:00",
		"symbol": "BTC/USD",
		"open": 41295.83,
		"high": 41339.24,
		"low": 41295.83,
		"close": 41339.24,
		"Volume BTC": 2.25194827,
		"Volume USD": 93093.83000111481
	},
	{
		"unix": 1646078040,
		"date": "2022-02-28 19:54:00",
		"symbol": "BTC/USD",
		"open": 41289.39,
		"high": 41314.5,
		"low": 41289.39,
		"close": 41309.19,
		"Volume BTC": 2.10186922,
		"Volume USD": 86826.51496413181
	},
	{
		"unix": 1646077980,
		"date": "2022-02-28 19:53:00",
		"symbol": "BTC/USD",
		"open": 41275.88,
		"high": 41291.9,
		"low": 41275.88,
		"close": 41289.39,
		"Volume BTC": 0.01835768,
		"Volume USD": 757.9774090152
	},
	{
		"unix": 1646077920,
		"date": "2022-02-28 19:52:00",
		"symbol": "BTC/USD",
		"open": 41261.55,
		"high": 41275.88,
		"low": 41251.89,
		"close": 41275.8,
		"Volume BTC": 0.7767248,
		"Volume USD": 32059.93749984
	},
	{
		"unix": 1646077860,
		"date": "2022-02-28 19:51:00",
		"symbol": "BTC/USD",
		"open": 41272.55,
		"high": 41272.55,
		"low": 41247.55,
		"close": 41247.55,
		"Volume BTC": 0.03172014,
		"Volume USD": 1308.378060657
	},
	{
		"unix": 1646077800,
		"date": "2022-02-28 19:50:00",
		"symbol": "BTC/USD",
		"open": 41236.35,
		"high": 41258.9,
		"low": 41235.21,
		"close": 41258.16,
		"Volume BTC": 0.10250741,
		"Volume USD": 4229.2671229656
	},
	{
		"unix": 1646077740,
		"date": "2022-02-28 19:49:00",
		"symbol": "BTC/USD",
		"open": 41240.25,
		"high": 41240.25,
		"low": 41224.96,
		"close": 41224.96,
		"Volume BTC": 0.20248565,
		"Volume USD": 8347.462821824
	},
	{
		"unix": 1646077680,
		"date": "2022-02-28 19:48:00",
		"symbol": "BTC/USD",
		"open": 41289.24,
		"high": 41289.25,
		"low": 41242.6,
		"close": 41242.6,
		"Volume BTC": 3.03121378,
		"Volume USD": 125015.13744302798
	},
	{
		"unix": 1646077620,
		"date": "2022-02-28 19:47:00",
		"symbol": "BTC/USD",
		"open": 41358.72,
		"high": 41358.72,
		"low": 41305.01,
		"close": 41309.08,
		"Volume BTC": 3.52262445,
		"Volume USD": 145516.375215006
	},
	{
		"unix": 1646077560,
		"date": "2022-02-28 19:46:00",
		"symbol": "BTC/USD",
		"open": 41363.82,
		"high": 41363.82,
		"low": 41341.94,
		"close": 41352.83,
		"Volume BTC": 0.062262,
		"Volume USD": 2574.70990146
	},
	{
		"unix": 1646077500,
		"date": "2022-02-28 19:45:00",
		"symbol": "BTC/USD",
		"open": 41324.09,
		"high": 41350.68,
		"low": 41324.09,
		"close": 41350.68,
		"Volume BTC": 4.02338,
		"Volume USD": 166369.49889840002
	},
	{
		"unix": 1646077440,
		"date": "2022-02-28 19:44:00",
		"symbol": "BTC/USD",
		"open": 41315.61,
		"high": 41406.03,
		"low": 41315.61,
		"close": 41320.45,
		"Volume BTC": 1.28890413,
		"Volume USD": 53258.09865845849
	},
	{
		"unix": 1646077380,
		"date": "2022-02-28 19:43:00",
		"symbol": "BTC/USD",
		"open": 41269.57,
		"high": 41324.13,
		"low": 41269.57,
		"close": 41307.03,
		"Volume BTC": 5.92239064,
		"Volume USD": 244636.3678381992
	},
	{
		"unix": 1646077320,
		"date": "2022-02-28 19:42:00",
		"symbol": "BTC/USD",
		"open": 41267.11,
		"high": 41269.3,
		"low": 41263.81,
		"close": 41269.3,
		"Volume BTC": 0.11432892,
		"Volume USD": 4718.274498156
	},
	{
		"unix": 1646077260,
		"date": "2022-02-28 19:41:00",
		"symbol": "BTC/USD",
		"open": 41235.43,
		"high": 41269.3,
		"low": 41235.43,
		"close": 41239.19,
		"Volume BTC": 0.42715,
		"Volume USD": 17615.3200085
	},
	{
		"unix": 1646077200,
		"date": "2022-02-28 19:40:00",
		"symbol": "BTC/USD",
		"open": 41221.82,
		"high": 41258.68,
		"low": 41206.41,
		"close": 41246.96,
		"Volume BTC": 0.07344371,
		"Volume USD": 3029.3297686215997
	},
	{
		"unix": 1646077140,
		"date": "2022-02-28 19:39:00",
		"symbol": "BTC/USD",
		"open": 41200,
		"high": 41234.09,
		"low": 41200,
		"close": 41234.09,
		"Volume BTC": 6.00770844,
		"Volume USD": 247722.39050871957
	},
	{
		"unix": 1646077080,
		"date": "2022-02-28 19:38:00",
		"symbol": "BTC/USD",
		"open": 41237.82,
		"high": 41238.03,
		"low": 41231.24,
		"close": 41231.28,
		"Volume BTC": 0.10811207,
		"Volume USD": 4457.5990295496
	},
	{
		"unix": 1646077020,
		"date": "2022-02-28 19:37:00",
		"symbol": "BTC/USD",
		"open": 41262.88,
		"high": 41269.3,
		"low": 41219.25,
		"close": 41219.25,
		"Volume BTC": 6.41017526,
		"Volume USD": 264222.616585755
	},
	{
		"unix": 1646076960,
		"date": "2022-02-28 19:36:00",
		"symbol": "BTC/USD",
		"open": 41231.97,
		"high": 41269.3,
		"low": 41231.43,
		"close": 41263.61,
		"Volume BTC": 10.276145,
		"Volume USD": 424030.83958345
	},
	{
		"unix": 1646076900,
		"date": "2022-02-28 19:35:00",
		"symbol": "BTC/USD",
		"open": 41216.39,
		"high": 41248.4,
		"low": 41213.81,
		"close": 41248.4,
		"Volume BTC": 0.29222447,
		"Volume USD": 12053.791828348001
	},
	{
		"unix": 1646076840,
		"date": "2022-02-28 19:34:00",
		"symbol": "BTC/USD",
		"open": 41195.61,
		"high": 41219.29,
		"low": 41195.61,
		"close": 41219.29,
		"Volume BTC": 0.01337,
		"Volume USD": 551.1019073
	},
	{
		"unix": 1646076780,
		"date": "2022-02-28 19:33:00",
		"symbol": "BTC/USD",
		"open": 41202.15,
		"high": 41227.39,
		"low": 41202.15,
		"close": 41216.13,
		"Volume BTC": 0.01513614,
		"Volume USD": 623.8531139382
	},
	{
		"unix": 1646076720,
		"date": "2022-02-28 19:32:00",
		"symbol": "BTC/USD",
		"open": 41188.94,
		"high": 41204.75,
		"low": 41184.28,
		"close": 41191.75,
		"Volume BTC": 3.0235889,
		"Volume USD": 124546.918071575
	},
	{
		"unix": 1646076660,
		"date": "2022-02-28 19:31:00",
		"symbol": "BTC/USD",
		"open": 41201.74,
		"high": 41231.93,
		"low": 41201.74,
		"close": 41222.66,
		"Volume BTC": 7.3082046,
		"Volume USD": 301263.633436236
	},
	{
		"unix": 1646076600,
		"date": "2022-02-28 19:30:00",
		"symbol": "BTC/USD",
		"open": 41145.31,
		"high": 41211.37,
		"low": 41145,
		"close": 41210.48,
		"Volume BTC": 1.3853437,
		"Volume USD": 57090.678841976005
	},
	{
		"unix": 1646076540,
		"date": "2022-02-28 19:29:00",
		"symbol": "BTC/USD",
		"open": 41152.24,
		"high": 41165.94,
		"low": 41145.05,
		"close": 41145.31,
		"Volume BTC": 1.80103233,
		"Volume USD": 74104.0335378723
	},
	{
		"unix": 1646076480,
		"date": "2022-02-28 19:28:00",
		"symbol": "BTC/USD",
		"open": 41148.29,
		"high": 41158.33,
		"low": 41117.85,
		"close": 41140.33,
		"Volume BTC": 4.54204951,
		"Volume USD": 186861.4157177383
	},
	{
		"unix": 1646076420,
		"date": "2022-02-28 19:27:00",
		"symbol": "BTC/USD",
		"open": 41128.71,
		"high": 41151.12,
		"low": 41128.7,
		"close": 41150.58,
		"Volume BTC": 0.52219218,
		"Volume USD": 21488.511078464402
	},
	{
		"unix": 1646076360,
		"date": "2022-02-28 19:26:00",
		"symbol": "BTC/USD",
		"open": 41180.71,
		"high": 41211.58,
		"low": 41180.71,
		"close": 41185.86,
		"Volume BTC": 0.38807,
		"Volume USD": 15982.996690200001
	},
	{
		"unix": 1646076300,
		"date": "2022-02-28 19:25:00",
		"symbol": "BTC/USD",
		"open": 41153.57,
		"high": 41174.39,
		"low": 41140.49,
		"close": 41174.39,
		"Volume BTC": 0.53676824,
		"Volume USD": 22101.1048533736
	},
	{
		"unix": 1646076240,
		"date": "2022-02-28 19:24:00",
		"symbol": "BTC/USD",
		"open": 41163.18,
		"high": 41172.45,
		"low": 41145.36,
		"close": 41172.45,
		"Volume BTC": 0.881813,
		"Volume USD": 36306.40165185
	}
];
export const normalizedData = btcDummyData2.map((obj) => {
	let time = new Date(obj.date);
	let timestamp = time.getTime(); //converts to 165839613182 format
	return ({
		time: timestamp,
		open: obj.open,
		high: obj.high,
		low: obj.low,
		close: obj.close
	});
});
export const normalizedDataReverse = normalizedData.reverse();